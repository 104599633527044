body {
    counter-reset: breadcrumb;
}

.breadcrumb {
    @extend %upper;
    @extend %body_r;
    @extend %relative;
    opacity: 0.8;

    @include mq( $until: md ) {
        
    }
        
    @include mq( $from: md, $until: lg ) {
        
    }
        
    @include mq( $from: lg ) {
        font-size: r(12);
    }
    
    @include after {
        @extend %body_bk;
        @extend %i_block;
        width: 2em;
        text-align: right;
        border-bottom: 1px solid currentColor;
        margin: 0 1em;
    }

    @include before( "0" counter(breadcrumb) ) {
        @extend %body_bk;
        @extend %i_block;
        @extend %absolute;
        counter-increment: breadcrumb;
        text-align: right;
        position: absolute;
        left: calc(100% + 0.3em);
        bottom: 0;
        transform: scale(1.3);
    }
}