// ================================================================
// TYPE
// ================================================================

%italic {
    font-style: italic;
}

%t_center {
    text-align: center;
}

%upper {
    text-transform: uppercase
}

%underline {
    text-decoration: underline;
}

// Variables
// ----------------------------------------------------------------

@each $font, $family in $FONTS {

    $underscore_idx: str-index( $font, "_" ) + 1;
    $font_category:  str-slice( $font, $underscore_idx, -1 );
    $font_category:  to-lower-case( $font_category );
    $placeholder:    "%" + $font_category;

    #{ $placeholder } {
        font-family: unquote( $family );

        &_t {                            //    %titles_t {        <-- "_t": thin
            @extend #{ $placeholder };   //        @extend %titles;
            font-weight: 100;            //        font-weight: 100;
        }                                //    }

        &_el {
            @extend #{ $placeholder };
            font-weight: 200;
        }

        &_l {
            @extend #{ $placeholder };
            font-weight: 300;
        }

        &_r {
            @extend #{ $placeholder };
            font-weight: 400;
        }

        &_m {
            @extend #{ $placeholder };
            font-weight: 500;
        }

        &_sb {
            @extend #{ $placeholder };
            font-weight: 600;
        }

        &_b {
            @extend #{ $placeholder };
            font-weight: 700;
        }

        &_eb {
            @extend #{ $placeholder };
            font-weight: 800;
        }

        &_bk {
            @extend #{ $placeholder };
            font-weight: 900;
        }

    }
}
