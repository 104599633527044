img {
    @extend %block;
    @extend %fluid;
    @extend %block;
    @extend %full_width;

    // Esto responde a un plugin que simula compatibilidad para

    &:not([src*=".svg"]):not([src*=".png"]) {
        font-family: 'object-fit: cover; object-position: bottom;';
        object-fit: cover;
        object-position: center;
    }

    &[src*=".png"] {
        font-family: 'object-fit: contain; object-position: center;';
        object-fit: contain;
        object-position: center;
    }

}

svg {
    fill: currentColor;
}
