.main-slider {
    $_: &;

    @extend %relative;
    
    &__item {
        background-color: darken(c(white), $DARKEN_FACTOR*0.2);
        box-shadow: 0 0 10em rgba(c(black), 0.2) inset;
        background-size: cover;

        @include mq( $until: md ) {
            padding: r( $MODULE*1.5 )
                     0
                     r( $MODULE/2 );
        }

        @include mq( $until: lg ) {
            border-bottom: r(10) solid currentColor;
        }
        
        @include mq( $from: md, $until: lg ) {
            padding: calc( #{ r( $MODULE/2 ) + r( map-get( $HEADER_HEIGHT, MD ) ) } )
                     0
                     r( $MODULE );
        }
            
        @include mq( $from: lg ) {
            padding: calc(#{ r( $MODULE ) + r( map-get( $HEADER_HEIGHT, LG ) ) } )
                     0
                     r( $MODULE );
            border-bottom: r(40) solid currentColor;
        }
    }

    &__inner {
        @extend %safe_pad;
        display: flex;
        align-items: center;

        @include mq( $and: "(orientation: portrait)") {
            flex-flow: column nowrap;

            @include mq( $until: md ) {
                height: 75vh;
            }
                
            @include mq( $from: md, $until: lg ) {
                height: calc( 80vh - #{ r( map-get( $HEADER_HEIGHT, MD )) } );
            }

        }

        @include mq( $and: "(orientation: landscape)") {
            
            @include mq( $until: md ) {
                height: 40vw;
            }
                
            @include mq( $from: md, $until: lg ) {
                height: 40vw;
            }
                
            @include mq( $from: lg ) {
                height: 55vh;
                min-height: r(400);
                max-height: r(500);
            }
        }

    }

    &__content {
        @extend %relative;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;

        @include mq( $and: "(orientation: portrait)") {
            width: 100%;

            &:first-child {
                order: 1;
            }
        }

        @include mq( $and: "(orientation: landscape)") {
            height: 100%;
            width: 50%;
            flex-basis: 50%;
            flex-shrink: 0;
            
        }
            

        svg {
            pointer-events: none;
            position: absolute;

            @include mq( $and: "(orientation: portrait)") {

                @include mq( $until: lg ) {
                    width: 10vw;
                    right: 0;
                    top: 1em;
                }

            }
    
            @include mq( $and: "(orientation: landscape)") {

                @include mq( $until: lg ) {
                    left: 100%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    height: 100%;
                    fill: rgba( c(black), 0.05 );
                }
                    
                @include mq( $from: lg ) {
                    width: r(65);
                    left: 100%;
                }
                
            }
            
            
        }
        
    }

    &__label {
        @extend %titles_b;
        @extend %upper;
        @extend %z_gotten;
        padding: 0.3em 1em;
        background-color: currentColor;

        @include mq( $until: md ) {
            display: none;
        }
            
        @include mq( $from: md, $until: lg ) {
            font-size: vw(18, $MD);
        }
            
        @include mq( $from: lg ) {
            font-size: r(13);
        }

        span {
            color: c(white);
        }
    }

    &__title {
        @extend %titles_eb;
        @extend %z_gotten;
        line-height: 1.1;
        margin: 0.5em 0;

        @include mq( $and: "(orientation: portrait)") {

            @include mq( $until: md ) {
                font-size: vw(55, $SM);
            }
            
            @include mq( $from: md, $until: lg ) {
                font-size: vw(80, $MD);
                max-width: 10em;
            }
                
            @include mq( $from: lg ) {
                font-size: vw(50, $LG);
            }
        }

        @include mq( $and: "(orientation: landscape)") {

            @include mq( $until: md ) {
                font-size: vw(40, $SM);
            }
            
            @include mq( $from: md, $until: lg ) {
                font-size: vw(50, $MD);
            }
                
            @include mq( $from: lg, $until: xl ) {
                font-size: vw(50, $LG);
            }

            @include mq( $from: lg ) {
                font-size: r(50);
                max-width: 11em;
            }
            
        }
        
        
    }

    &__subtitle {
        @extend %body_r;
        
        @extend %z_gotten;
        margin-bottom: 1.5em;

        @include mq( $until: md ) {
            display: none;
        }
            
        @include mq( $from: md, $until: lg ) {
            font-size: vw(23, $MD);
        }
            
        @include mq( $from: lg ) {
            font-size: r(18);
            max-width: 25em;
            
        }
        
        > span {
            @extend %txt_body;
        }

        > a {
            @extend %pointer;
            text-decoration: underline;
        }

    }

    

    &__imgs {
        @extend %full;
        

        @include mq( $and: "(orientation: portrait)") {

            @include mq( $until: md ) {
                height: 70vw;
            }
                
            @include mq( $from: md, $until: lg ) {
                height: 60vw;
            }
            
        }

        &-nav {
            @extend %full_width;
            @extend %absolute;
            letter-spacing: -0.02em;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 2em;
            bottom: 0;

            @include mq( $until: lg ) {
                display: none;
            }

            i {
                @extend %relative;
                @extend %pointer;
                @extend %block;
                margin: 0.8em 0.7em;

                &:not(.fa) {
                    @extend %body_m;
                    font-size: 1em;
                }

                &[data-slider-action*="slickGoTo"]:not(.Active) {
                    opacity: 0.6;
                }


                &.Active {
                    @include after {
                        @extend %absolute;
                        width: 0.9em;
                        top: 100%;
                        left: 50%;
                        transform: translateX(-50%);
                        height: r(4);
                        background-color: currentColor;
                    }
                }
            }
        }

        * {
            height: 100%;
        }

        
    }

    &__img {
        @include center_contents;

        @include mq( $until: md ) {
            
        }
            
        @include mq( $from: md, $until: lg ) {
            
        }
            
        @include mq( $from: md ) {
            
        }

        img {
        }
    }

    &__caracts {
        @include mq( $until: lg ) {
            display: none;
        }
            
        @include mq( $from: lg ) {
            display: flex;
            margin-bottom: 3em;
        }
    }

    &__caract {
        
        @include size( 2em 2.2em );
        @include center_contents;
        background-color: currentColor;
        font-size: 1.6em;
        padding: 0.1em;
        border-radius: 0.2em;
        @include cloner {
            margin-right: r(10);
        }

        i {
            @extend %white;
            font-size: inherit;
        }
    }

    &__btns {
        @extend %z_gotten;
        @extend %full_width;
        display: flex;
        align-items: center;
        margin-top: auto;

        @include mq( $until: md ) {
            font-size: 1.1em;
            font-size: 2.5vmax;
        }
            
        @include mq( $from: md, $until: lg ) {
            font-size: 1.1em;
        }
            
            
        @include mq( $until: lg ) {
            flex-flow: row wrap;
            justify-content: space-between;
        }
            
        @include mq( $from: lg ) {
            border-top: 1px solid currentColor;
            padding-top: 1em;
            
        }

        button.btn, a.btn { // < Necesité darle más puntaje para sobreescribir el margin
            font-size: inherit;

            @include mq( $until: lg ) {
                flex-grow: 1;
                flex-basis: 100%;
                margin-right: 0;
                margin-top: 1em;

                &[download] {
                    display: none;
                }
            }
        }
    }

    > .btn { // Scroll
        @extend %absolute;
        @extend %z_gotten;
        left: 50%;
        transform: translateX(-50%);

        @include mq( $until: md ) {
            font-size: 1.5em;
        }
        
            
        @include mq( $from: md, $until: lg ) {
            font-size: 2.1em;
        }

        @include mq( $until: lg ) {
            top: 100%;
            transform: translate(-50%, -60%);

            [scroll] {
                display: none;
            }
        }
            
        @include mq( $from: lg ) {
            font-size: 2em;
            bottom: 3px;
            transform: translateX(-50%);

            [drag-horizontal] {
                display: none;
            }
        }

    }

    &__nav {
        @extend %absolute;
        

        @include mq( $until: lg ) {
            display: none;
        }

        @include mq( $from: lg, $until: xl ) {
           right: 1em;
        }
            
        @include mq( $from: lg ) {
            font-size: 2em;
            bottom: r($MODULE*1.5);
        }

        @include mq( $from: xl ) {
            right: website_margins($LG);
            transform: translateX(2vw);
        }

        .btn {
            @extend %block;
            
            @include cloner {
                margin-bottom: r(10);
            }
        }
    }

    .slick-slide {
        outline: none;
    }

    .slick-dots {
        @extend %absolute;
        top: 50%;
        
        @include mq( $until: lg ) {
            display: none !important;
        }

        @include mq( $from: lg, $until: xl ) {
            left: 1em;
        }
            
        @include mq( $from: xl ) {
            left: website_margins($LG);
            transform: translateX(-2vw);
        }

        

        li {
            @extend %transition;
            @extend %pointer;
            @include size(1.2em);
            margin: 0.6em 0;
            border-radius: 50%;
            box-shadow: 0 0 6px rgba(c(black), 0.5) inset;


            &.slick-active {
                background-color: currentColor;
            }

            * {
                display: none;
            }
        }

        
    }
}