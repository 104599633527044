// ================================================================
// COLOR
// ================================================================

@each $color, $value in $COLORS {

    %#{$color} {
        color: $value;
    }

    %#{$color}_bg {
        background-color: $value;
    }

    %#{$color}_lt {
        color: lighten($value, $LIGHTEN_FACTOR)
    }

    %#{$color}_bg_lt {
        background-color: lighten($value, $LIGHTEN_FACTOR)
    }

    %#{$color}_dk {
        color: darken($value, $DARKEN_FACTOR)
    }

    %#{$color}_bg_dk {
        background-color: darken($value, $DARKEN_FACTOR)
    }

    %#{$color}_bg_t {
        background-color: rgba($value, $TRANSPARENCY_FACTOR);
    }

    [color-scope="#{$color}"] {
        @extend %#{$color}_bg;
    }

}
