@mixin global_input {
    @extend %full_width;
    @extend %block;
    @extend %transition;
    @extend %txt_body;
    @extend %body_r;
    appearance: none;
    border: none;
    border-bottom: 1px solid c(brand);
    padding: 0.6em 1em;
    margin-top: 1em;
    margin-bottom: 1em;
    outline: none;
    box-shadow: none;
    background-color: lighten( c(txt_body), $LIGHTEN_FACTOR*1.12 );
    height: 2.5em;

    &:focus {
        @extend %brand_bg;
        @extend %white;
        box-shadow: 0 0 1em rgba(c(black), 0.2) inset;
    }

    &::placeholder {
        opacity: 1;
    }
}

form {
    @extend %body_l;
}

button[type="submit"] ,
input[type="submit"] {
    @extend %pointer;
    @extend %z_gotten;
    @extend %relative;
    background-color: c(brand);
    border: none;
    outline: none;
    background-color: transparent;
    color: inherit;
    text-transform: inherit;
    margin-top: 1em;
}

[type="submit"][disabled] {
    @extend %txt_body_bg;
    pointer-events: none;
}

[type="submit"].Thinking {
    pointer-events: none;
}

[type="checkbox"]  {
    @extend .h;
    
    
    + i {
        @extend %brand;
        @extend %block;
        @include center_contents;
        @include size(1.5em);
        border: 3px solid c(brand);
        margin-left: 1em;

    }

    &:checked + i {
        @extend %brand_bg;
        @extend %white;
    }
    
    
}


textarea,
select, 
input:not([type="submit"]):not([type="checkbox"]) {
    @include global_input;
}

select {
    @extend %pointer;
}

textarea {
    resize: none;
    height: auto;
}

label {
    

    &:not([for="newsletter"]) {

        @extend %titles_eb;
        @extend %brand;
        @extend %upper;

        @include mq( $until: md ) {
            
        }
            
        @include mq( $from: md, $until: lg ) {
            
        }

        @include mq( $from: lg, $until: xl ) {
            font-size: vw(12.5, $LG);
        }
            
        @include mq( $from: lg ) {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-flow: row wrap;
            
    
            input, select, textarea, [styled-select] {
                width: 60%;
                flex-basis: 60%;
                flex-grow: 0;
                flex-shrink: 0;
                margin-left: 2em;
            }
        }

        @include mq( $from: xl ) {
            font-size: r(13);
        }
    }

    &[for="consulta-por"] {
        // @extend %relative;
        // @extend %block;
        // overflow: auto;
        
        // @include after( "\f0d7" ) {
        //     @extend %absolute;
        //     @include center_contents;
        //     pointer-events: none;
        //     height: 2.3em;
        //     width: 2.3em;
        //     bottom: 1em;
        //     right: 0;
        //     font-family: FontAwesome;
        // }
    }

    &[for="newsletter"] {
        @extend %body_r;
        @extend %flex;
        @extend %pointer;
        justify-content: flex-end;
        font-size: r(12);
        user-select: none;
    }

    
}

