@charset "UTF-8";

@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,700,800,900|Roboto:300,400,500,700,900");

main,
.pace,
a,
button,
textarea,
select,
input:not([type="submit"]):not([type="checkbox"]),
.header,
.header__link,
.styled-select__placeholder,
.styled-select__item,
.btn i,
.btn i::before,
.btn.Basic,
.btn.Basic span,
.product__actions,
.main-slider .slick-dots li,
.contact .grecaptcha-badge {
  transition: all 0.2s ease-out;
}

[scroll] svg {
  animation-name: scroll;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

[drag-horizontal] svg {
  animation-name: drag_horizontal;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

@keyframes scroll {
  0% {
    will-change: transform;
  }

  10% {
    transform: translateY(-10%);
  }

  20% {
    transform: translateY(10%);
  }
}

@keyframes drag_horizontal {
  0% {
    will-change: transform;
  }

  50% {
    transform: translateX(-20%);
  }
}

[type="checkbox"] + i,
label:not([for="newsletter"]),
.styled-select__placeholder::before,
.styled-select__item:hover,
.styled-select__item.Active,
.notification,
.content__foot .btn,
.about [drag-horizontal],
.contact__title {
  color: #ee3343;
}

[color-scope="brand"],
::selection,
.pace .pace-progress,
[type="checkbox"]:checked + i,
textarea:focus,
select:focus,
input:not([type="submit"]):not([type="checkbox"]):focus,
.styled-select__placeholder:focus,
.styled-select__item:focus,
.about,
.data__info::after,
.data__info-item i,
.contact__title::after {
  background-color: #ee3343;
}

[color-scope="control"] {
  color: #0DB14B;
}

[color-scope="control_primary"],
[color-scope="control"] *::selection {
  background-color: #0DB14B;
}

[color-scope="control_secondary"] {
  background-color: #6FBE44;
}

[color-scope="control_teriary"] {
  background-color: #15892e;
}

[color-scope="proteccion"] {
  color: #553E90;
}

[color-scope="proteccion_primary"],
[color-scope="proteccion"] *::selection {
  background-color: #553E90;
}

[color-scope="proteccion_secondary"] {
  background-color: #A39ECE;
}

[color-scope="proteccion_teriary"] {
  background-color: #2B223D;
}

[color-scope="sensitiva"] {
  color: #3977BA;
}

[color-scope="sensitiva_primary"],
[color-scope="sensitiva"] *::selection {
  background-color: #3977BA;
}

[color-scope="sensitiva_secondary"] {
  background-color: #45B3E6;
}

[color-scope="sensitiva_teriary"] {
  background-color: #365591;
}

[color-scope="instalacion"] {
  color: #EE5523;
}

[color-scope="instalacion_primary"],
[color-scope="instalacion"] *::selection {
  background-color: #EE5523;
}

[color-scope="instalacion_secondary"] {
  background-color: #FAA41A;
}

[color-scope="instalacion_teriary"] {
  background-color: #CC3300;
}

::selection,
[type="checkbox"]:checked + i,
textarea:focus,
select:focus,
input:not([type="submit"]):not([type="checkbox"]):focus,
.styled-select__placeholder:focus,
.styled-select__item:focus,
.notification__btn,
.notification > i,
.notification h2,
.notification p,
.notification a[href*="#"],
.main-slider__caract i,
.about,
.data__info-item i {
  color: #ffffff;
}

[color-scope="white"],
.header,
.product__model::after,
.product-single,
.gm-style-iw,
.Productos .content__body [drag-horizontal],
.about [drag-horizontal],
.data__info {
  background-color: #ffffff;
}

[color-scope="black"],
.notification::after {
  background-color: #131313;
}

.modal {
  background-color: rgba(19, 19, 19, 0.5);
}

body,
textarea,
select,
input:not([type="submit"]):not([type="checkbox"]),
.styled-select__placeholder,
.styled-select__item,
.product__model dt,
.product-single__type,
.product-single__subtitle,
.product-single__desc,
.main-slider__subtitle > span {
  color: #777776;
}

[color-scope="txt_body"],
[type="submit"][disabled] {
  background-color: #777776;
}

.footer__copyright-item,
.product-single__imgs {
  background-color: #e9e9e9;
}

img {
  max-width: 100%;
}

.modal,
.main-slider__imgs,
.Productos .content__body > article h2::before,
img,
textarea,
select,
input:not([type="submit"]):not([type="checkbox"]),
.header,
.styled-select__placeholder,
.styled-select__items,
.styled-select__item,
.product-single__imgs-nav,
.main-slider__imgs-nav,
.main-slider__btns,
.gm-style-iw::before,
.Productos .products {
  width: 100%;
}

.modal,
.main-slider__imgs,
.Productos .content__body > article h2::before,
.about::before,
.about::after {
  height: 100%;
}

img,
[type="checkbox"] + i,
textarea,
select,
input:not([type="submit"]):not([type="checkbox"]),
.header__logo svg,
.styled-select__placeholder,
.styled-select__placeholder::after,
.styled-select__placeholder::before,
.styled-select__item,
.notification::after,
.product__model::after,
.product-single::after,
.product-single__imgs-nav > i,
.main-slider__imgs-nav i,
.main-slider__nav .btn,
.gm-style-iw::before,
.Productos::before,
.about::before,
.about::after,
.about__title::after,
.data__info::after,
.data__info-item p > span,
.data__info-item p > span > small,
.contact__title::after {
  display: block;
}

.breadcrumb::after,
.breadcrumb::before,
.product__model dt::after {
  display: inline-block;
}

label[for="newsletter"],
.header__nav,
.footer__copyright-item,
.notification,
.product,
.product__model,
.Productos .products__row,
.about__mkt,
.data,
.data__info-item,
.contact {
  display: flex;
}

.styled-select__placeholder::after,
.styled-select__placeholder::before,
.styled-select__placeholder > span,
.styled-select__items,
.breadcrumb::before,
.notification::after,
.notification__btn,
.product__actions,
.product-single::after,
.product-single__warranty,
.product-single__imgs-nav,
.product-single__content svg,
.main-slider__imgs-nav,
.main-slider__imgs-nav i.Active::after,
.main-slider > .btn,
.main-slider__nav,
.main-slider .slick-dots,
.gm-style-iw::after,
.Productos::before,
.Productos .content__body > article h2::before,
.about::before,
.about::after,
.about__title::after,
.about .breadcrumb,
.data__info::after,
.data .breadcrumb,
.contact__title::after,
.contact .breadcrumb {
  position: absolute;
}

button[type="submit"],
input[type="submit"],
.header__nav,
.header__link,
.styled-select,
.breadcrumb,
button,
.product,
.product-single,
.product-single__slider,
.product-single__imgs-nav > i,
.product-single__content,
.main-slider,
.main-slider__content,
.main-slider__imgs-nav i,
.Productos,
.about,
.about__title,
.data,
.data__info,
.contact {
  position: relative;
}

button[type="submit"],
input[type="submit"],
.styled-select__placeholder::before,
button,
.product-single__warranty,
.main-slider__label,
.main-slider__title,
.main-slider__subtitle,
.main-slider__btns,
.main-slider > .btn,
.Productos::before,
.Productos .content__body [drag-horizontal],
.about [drag-horizontal],
.data__info {
  z-index: 200;
}

.header,
.styled-select__items,
.product-single__close {
  z-index: 300;
}

.pace,
a:active {
  z-index: 500;
}

.Productos .content__body > article h2::before {
  z-index: -1;
}

[grid] {
  display: flex;
}

[columns] {
  flex-grow: 0;
  flex-shrink: 0;
}

label:not([for="newsletter"]),
.header,
.footer__copyright-item,
.breadcrumb,
.btn.Basic,
.product__model,
.product-single__type,
.main-slider__label {
  text-transform: uppercase;
}

.about__title,
.Productos .content__body > article h2 span,
.contact__title,
body,
.product-single__subtitle,
.product-single__type,
.btn.Basic,
.notification h2,
.main-slider__label,
.about__mkt-title,
label:not([for="newsletter"]),
.product__title,
.product-single__title,
.main-slider__title,
.Productos .content__body > article h2,
.about__title strong,
.contact__title > b {
  font-family: 'Raleway', 'Helvetica', 'Arial', sans-serif;
}

.about__title {
  font-weight: 100;
}

.Productos .content__body > article h2 span,
.contact__title {
  font-weight: 300;
}

body {
  font-weight: 400;
}

.product-single__subtitle {
  font-weight: 500;
}

.product-single__type {
  font-weight: 600;
}

.btn.Basic,
.notification h2,
.main-slider__label,
.about__mkt-title {
  font-weight: 700;
}

label:not([for="newsletter"]),
.product__title,
.product-single__title,
.main-slider__title,
.Productos .content__body > article h2,
.about__title strong,
.contact__title > b {
  font-weight: 800;
}

form,
.notification p,
.notification a[href*="#"],
.product-single__desc,
.Productos .content__body > article p,
.about__main,
.about__mkt-desc,
.data__info,
.data__info-item p > span > span,
.data__info-item p > span > small,
.contact__title span,
textarea,
select,
input:not([type="submit"]):not([type="checkbox"]),
label[for="newsletter"],
.styled-select__placeholder,
.styled-select__item,
.breadcrumb,
.main-slider__subtitle,
.product-single__imgs-nav > i:not(.fa),
.main-slider__imgs-nav i:not(.fa),
.header,
.footer__copyright-item,
.product__model,
.product-single__desc strong,
.data__info-item p > span,
.breadcrumb::after,
.breadcrumb::before {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

form,
.notification p,
.notification a[href*="#"],
.product-single__desc,
.Productos .content__body > article p,
.about__main,
.about__mkt-desc,
.data__info,
.data__info-item p > span > span,
.data__info-item p > span > small,
.contact__title span {
  font-weight: 300;
}

textarea,
select,
input:not([type="submit"]):not([type="checkbox"]),
label[for="newsletter"],
.styled-select__placeholder,
.styled-select__item,
.breadcrumb,
.main-slider__subtitle {
  font-weight: 400;
}

.product-single__imgs-nav > i:not(.fa),
.main-slider__imgs-nav i:not(.fa) {
  font-weight: 500;
}

.header,
.footer__copyright-item,
.product__model {
  font-weight: 700;
}

.product-single__desc strong,
.data__info-item p > span {
  font-weight: 800;
}

.breadcrumb::after,
.breadcrumb::before {
  font-weight: 900;
}

button[type="submit"],
input[type="submit"],
select,
label[for="newsletter"],
.styled-select,
button,
.btn,
.btn.Basic,
.notification__btn,
.product,
.product-single__imgs-nav > i,
.main-slider__subtitle > a,
.main-slider__imgs-nav i,
.main-slider .slick-dots li {
  cursor: pointer;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

main[v-cloak] {
  opacity: 0;
}

body {
  overflow: hidden;
}

body:not(.pace-done) .pace::before {
  content: "";
  opacity: 1;
}

.pace-running {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

.pace-running .pace {
  opacity: 1 !important;
}

.pace {
  height: 100%;
  width: 100%;
  pointer-events: none;
  user-select: none;
  position: fixed;
  opacity: 0;
  top: 0;
  left: 0;
}

.pace-inactive {
  opacity: 0;
}

.pace .pace-progress {
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 3px;
}

@keyframes loader {
  0% {
    opacity: 1;
    transform: rotateY(0);
  }

  40% {
    opacity: 0.8;
    transform: rotateY(360deg);
  }

  100% {
    opacity: 1;
    transform: rotateY(0);
  }
}

[data-before]::before {
  content: attr(data-before);
}

[data-after]::after {
  content: attr(data-after);
}

a:active,
button:active {
  transform: scale(0.95);
}

[scroll] svg {
  width: 0.7em;
}

[drag-down] svg {
  width: 0.7em;
}

[drag-horizontal] svg {
  width: 0.8em;
  transform: translateX(-5%);
}

img:not([src*=".svg"]):not([src*=".png"]) {
  font-family: 'object-fit: cover; object-position: bottom;';
  object-fit: cover;
  object-position: center;
}

img[src*=".png"] {
  font-family: 'object-fit: contain; object-position: center;';
  object-fit: contain;
  object-position: center;
}

svg {
  fill: currentColor;
}

html {
  overflow-x: hidden;
  font-size: 1em;
}

body {
  line-height: 1.4;
  font-variant-numeric: lining-nums;
  font-feature-settings: "lnum";
}

a {
  text-decoration: none;
  color: inherit;
}

a:active {
  transform: scale(0.97);
}

strong {
  font-weight: 700;
}

.h,
[type="checkbox"] {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden !important;
}

button[type="submit"],
input[type="submit"] {
  background-color: #ee3343;
  border: none;
  outline: none;
  background-color: transparent;
  color: inherit;
  text-transform: inherit;
  margin-top: 1em;
}

[type="submit"][disabled] {
  pointer-events: none;
}

[type="submit"].Thinking {
  pointer-events: none;
}

[type="checkbox"] + i {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.5em;
  width: 1.5em;
  border: 3px solid #ee3343;
  margin-left: 1em;
}

textarea,
select,
input:not([type="submit"]):not([type="checkbox"]) {
  appearance: none;
  border: none;
  border-bottom: 1px solid #ee3343;
  padding: 0.6em 1em;
  margin-top: 1em;
  margin-bottom: 1em;
  outline: none;
  box-shadow: none;
  background-color: #f7f7f7;
  height: 2.5em;
}

textarea:focus,
select:focus,
input:not([type="submit"]):not([type="checkbox"]):focus {
  box-shadow: 0 0 1em rgba(19, 19, 19, 0.2) inset;
}

textarea::placeholder,
select::placeholder,
input:not([type="submit"]):not([type="checkbox"])::placeholder {
  opacity: 1;
}

textarea {
  resize: none;
  height: auto;
}

label[for="newsletter"] {
  justify-content: flex-end;
  font-size: 0.75rem;
  user-select: none;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}

.header.Mini {
  color: #ee3343;
}

.header.Mini:not(:hover) {
  background-color: rgba(255, 255, 255, 0.8);
}

.footer__copyright-item {
  align-items: center;
  padding: 0.8em 1.2em;
}

.footer__copyright-item:not(:last-child) {
  margin-right: 1em;
}

.footer svg {
  height: 2em;
  width: 2em;
  margin-right: 1em;
}

.footer path {
  fill: #ee3343 !important;
}

.styled-select {
  max-width: 100%;
}

.styled-select:not(.Active) .styled-select__items {
  display: none;
}

.styled-select:not(.Active) .styled-select__placeholder::before {
  content: "";
}

.styled-select:not(.Active) .styled-select__overlay {
  display: none;
}

.styled-select__overlay {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
}

.styled-select__placeholder {
  appearance: none;
  border: none;
  border-bottom: 1px solid #ee3343;
  padding: 0.6em 1em;
  margin-top: 1em;
  margin-bottom: 1em;
  outline: none;
  box-shadow: none;
  background-color: #f7f7f7;
  height: 2.5em;
  overflow: hidden;
  position: inherit;
  user-select: none;
}

.styled-select__placeholder:focus {
  box-shadow: 0 0 1em rgba(19, 19, 19, 0.2) inset;
}

.styled-select__placeholder::placeholder {
  opacity: 1;
}

.styled-select__placeholder::after {
  content: "";
  height: 100%;
  width: 4em;
  background: linear-gradient(to left, #f7f7f7 55%, rgba(247, 247, 247, 0.5) 80%, rgba(247, 247, 247, 0));
  right: 0;
  top: 0;
}

.styled-select__placeholder::before {
  content: "";
  height: 100%;
  width: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: FontAwesome;
  right: 0;
  top: 0;
}

.styled-select__placeholder > span {
  white-space: nowrap;
  text-transform: none;
  left: 0;
  padding: inherit;
  top: 0;
}

.styled-select__items {
  top: 3.1em;
  left: 0;
  font-size: 0.9em;
  max-height: 15em;
  overflow-y: scroll;
  user-select: none;
}

.styled-select__item {
  appearance: none;
  border: none;
  border-bottom: 1px solid #ee3343;
  padding: 0.6em 1em;
  margin-top: 1em;
  margin-bottom: 1em;
  outline: none;
  box-shadow: none;
  background-color: #f7f7f7;
  height: 2.5em;
  text-align: left;
  margin: 0;
  border-color: #777776;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: none;
}

.styled-select__item:focus {
  box-shadow: 0 0 1em rgba(19, 19, 19, 0.2) inset;
}

.styled-select__item::placeholder {
  opacity: 1;
}

.styled-select__item:hover {
  background-color: #e9e9e9;
}

.styled-select__item.Active::after {
  content: "";
  font-family: FontAwesome;
}

body {
  counter-reset: breadcrumb;
}

.breadcrumb {
  opacity: 0.8;
}

.breadcrumb::after {
  content: "";
  width: 2em;
  text-align: right;
  border-bottom: 1px solid currentColor;
  margin: 0 1em;
}

.breadcrumb::before {
  content: "0" counter(breadcrumb);
  counter-increment: breadcrumb;
  text-align: right;
  position: absolute;
  left: calc(100% + 0.3em);
  bottom: 0;
  transform: scale(1.3);
}

button {
  border: none;
  outline: none;
  background-color: transparent;
  color: inherit;
}

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.btn:hover i::before {
  opacity: 0.6;
}

.btn i {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2em;
  width: 2em;
  display: inline-flex;
  font-size: 1.2em;
  border-radius: 50em;
}

.btn i.fa-chevron-right::before {
  transform: translateX(10%);
}

.btn i.fa-chevron-left::before {
  transform: translateX(-10%);
}

.btn.Basic i {
  margin-right: 0.4em;
}

.btn.Icon i {
  background-color: #ffffff;
  box-shadow: 0 0 0.3em rgba(19, 19, 19, 0.2);
}

.btn.Alt {
  border-radius: 10em;
  background-color: currentColor;
  overflow: hidden;
  padding-left: 0.5em;
  padding-right: 2em;
  height: 3em;
}

.btn.Alt:hover span {
  opacity: 0.6;
}

.btn.Alt i {
  color: #ffffff;
}

.btn.Alt span {
  color: #ffffff;
}

.notification {
  z-index: 1002;
  position: fixed;
  padding: 1.5em 2em 2em;
  align-items: center;
  bottom: 5%;
  animation-name: notif;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
  box-shadow: 0 0 2em rgba(19, 19, 19, 0.3);
  background-color: currentColor;
}

.notification::after {
  content: "";
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 2em * 2);
  height: 0.8em;
  bottom: 0;
  opacity: 0.1;
}

.notification__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.2em;
  width: 2.2em;
  font-size: 1.1em;
  top: 0;
  right: 0;
  padding: 1.2em;
}

.notification h2 {
  font-size: 1.2em;
}

.notification p {
  opacity: 0.8;
  font-size: 0.9em;
}

.notification a[href*="#"] {
  text-decoration: underline;
  font-size: 0.9em;
}

@keyframes notif {
  0% {
    transform: translateY(1em);
    opacity: 0;
  }

  10% {
    transform: translateY(0);
    opacity: 1;
  }

  90% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(1em);
    opacity: 0;
  }
}

.product {
  flex-direction: column;
  justify-content: space-between;
  font-size: 1.125rem;
  line-height: 1.2;
  user-select: none;
}

.product__model {
  margin-top: 1em;
  letter-spacing: -0.02em;
  align-items: center;
}

.product__model::after {
  content: "";
  font-family: FontAwesome;
  margin-left: auto;
  line-height: 0;
}

.product__model dt::after {
  content: "";
  width: 2em;
  border-bottom: 1px solid currentColor;
  margin: 0 0.3em;
}

.product__actions {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: currentColor;
  height: calc(100% + 1.5rem);
  width: calc(100% + 0.5rem);
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
}

.product__actions svg {
  fill: #ffffff;
  height: 60%;
}

.product:hover .product__actions {
  opacity: 1;
}

.product .btn {
  margin: 0;
  padding: 0;
  font-size: 0.8em;
}

.modal {
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  background-image: url("../img/pattern.svg");
  background-size: cover;
  max-height: 100vh;
  overflow-y: scroll;
}

.product-single {
  box-shadow: 0 0 2em rgba(19, 19, 19, 0.3);
}

.product-single::after {
  content: "";
}

.product-single [class*="slick"] {
  outline: none;
}

.product-single__warranty {
  fill: currentColor;
}

.product-single__imgs {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-single__imgs *:not(img) {
  height: 100%;
  width: 100%;
}

.product-single__imgs-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8125rem;
  bottom: 0;
}

.product-single__imgs-nav > i {
  padding: 1em 0.6em;
}

.product-single__imgs-nav > i:not(.fa) {
  font-size: 1.2em;
}

.product-single__imgs-nav > i[data-slider-action*="slickGoTo"]:not(.Active) {
  opacity: 0.6;
}

.product-single__img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-single__img img {
  width: auto;
  margin: 0 auto;
}

.product-single__title {
  line-height: 1.1;
  margin: 0.15em 0 0.35em;
}

.product-single__desc {
  margin: 1em 0 1.5em;
}

.product-single__desc strong {
  line-height: 2;
}

.product-single__close {
  position: fixed;
  right: 1rem;
}

.main-slider__item {
  background-color: #fafafa;
  box-shadow: 0 0 10em rgba(19, 19, 19, 0.2) inset;
  background-size: cover;
}

.main-slider__inner {
  display: flex;
  align-items: center;
}

.main-slider__content {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
}

.main-slider__content svg {
  pointer-events: none;
  position: absolute;
}

.main-slider__label {
  padding: 0.3em 1em;
  background-color: currentColor;
}

.main-slider__label span {
  color: #ffffff;
}

.main-slider__title {
  line-height: 1.1;
  margin: 0.5em 0;
}

.main-slider__subtitle {
  margin-bottom: 1.5em;
}

.main-slider__subtitle > a {
  text-decoration: underline;
}

.main-slider__imgs-nav {
  letter-spacing: -0.02em;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2em;
  bottom: 0;
}

.main-slider__imgs-nav i {
  margin: 0.8em 0.7em;
}

.main-slider__imgs-nav i:not(.fa) {
  font-size: 1em;
}

.main-slider__imgs-nav i[data-slider-action*="slickGoTo"]:not(.Active) {
  opacity: 0.6;
}

.main-slider__imgs-nav i.Active::after {
  content: "";
  width: 0.9em;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  height: 0.25rem;
  background-color: currentColor;
}

.main-slider__imgs * {
  height: 100%;
}

.main-slider__img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-slider__caract {
  height: 2.2em;
  width: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: currentColor;
  font-size: 1.6em;
  padding: 0.1em;
  border-radius: 0.2em;
}

.main-slider__caract:not(:last-child) {
  margin-right: 0.625rem;
}

.main-slider__caract i {
  font-size: inherit;
}

.main-slider__btns {
  display: flex;
  align-items: center;
  margin-top: auto;
}

.main-slider__btns button.btn,
.main-slider__btns a.btn {
  font-size: inherit;
}

.main-slider > .btn {
  left: 50%;
  transform: translateX(-50%);
}

.main-slider__nav .btn:not(:last-child) {
  margin-bottom: 0.625rem;
}

.main-slider .slick-slide {
  outline: none;
}

.main-slider .slick-dots {
  top: 50%;
}

.main-slider .slick-dots li {
  height: 1.2em;
  width: 1.2em;
  margin: 0.6em 0;
  border-radius: 50%;
  box-shadow: 0 0 6px rgba(19, 19, 19, 0.5) inset;
}

.main-slider .slick-dots li.slick-active {
  background-color: currentColor;
}

.main-slider .slick-dots li * {
  display: none;
}

.content__head {
  margin-bottom: 5rem;
}

.content__head::after {
  background-color: #e9e9e9;
  content: "";
  display: block;
  position: relative;
  width: 100vw;
  transform: translateX(-50%);
  left: 50%;
  height: 1px;
  margin-top: 1.5rem;
}

.content__foot::before {
  background-color: #e9e9e9;
  content: "";
  display: block;
  position: relative;
  width: 100vw;
  transform: translateX(-50%);
  left: 50%;
  height: 1px;
  margin-bottom: 1.5rem;
}

.iwParent {
  width: 18.75rem !important;
  height: auto !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
}

.gm-style-iw {
  border-bottom: 0.2em solid #ee3343;
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
  overflow: visible !important;
}

.gm-style-iw::before {
  content: "";
  height: 5.625rem;
  background-image: url("../img/logo.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 14.375rem auto;
}

.gm-style-iw::after {
  content: "";
  height: 0;
  width: 0;
  border-left: 1em solid transparent;
  border-right: 1em solid transparent;
  border-top: 1em solid #ee3343;
  top: calc(100% + 0.2em);
  left: 50%;
  transform: translateX(-50%);
}

.gm-style-iw > div {
  display: none !important;
}

.iwClose {
  display: none;
}

.iwSibling div {
  display: none;
}

.Productos::before {
  content: "";
  pointer-events: none;
  min-width: 5vw;
  background: linear-gradient(to left, white 55%, rgba(255, 255, 255, 0.5) 80%, rgba(255, 255, 255, 0));
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.Productos .content__body > article h2 {
  line-height: 1;
  margin-bottom: 0.4em;
}

.Productos .content__body > article h2::before {
  content: "";
  opacity: 0.02;
  filter: invert(1);
  pointer-events: none;
  left: 0;
  top: 0;
  background-image: url("../img/loader.svg");
  background-repeat: no-repeat;
}

.Productos .content__body [drag-horizontal] {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3em;
  width: 1.5em;
  left: 100%;
  top: 50%;
  position: absolute;
  transform: translate(-100%, -50%);
  box-shadow: 0 0 0.3em rgba(19, 19, 19, 0.2);
  border-top-left-radius: 500em;
  border-bottom-left-radius: 500em;
}

.Productos .products__row {
  padding-right: 60em;
}

.about::before,
.about::after {
  opacity: 0.2;
  border-width: 1px;
  border-color: #ffffff;
  border-top-style: solid;
  top: 5rem;
  pointer-events: none;
}

.about::after {
  content: "";
  border-left-style: solid;
  width: 30vw;
  right: 0;
}

.about::before {
  content: "";
  border-right-style: solid;
  width: 10vw;
  left: 0;
}

.about__title::after {
  content: "";
  background-image: url("../img/loader.svg");
  background-size: contain;
  background-repeat: no-repeat;
  top: 50%;
}

.about__mkt-title {
  margin-bottom: 1em;
}

.about [drag-horizontal] {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2em;
  width: 2em;
  box-shadow: 0 0 0.3em rgba(19, 19, 19, 0.2);
  border-radius: 50%;
  font-size: 2em;
  margin: 0.5em auto 0;
}

.about .breadcrumb {
  transform: rotateZ(-90deg);
  left: 80vw;
  bottom: 20rem;
  white-space: nowrap;
}

.data {
  overflow: visible;
}

.data__info::after {
  content: "";
  height: calc( 100% - 15rem);
  top: 7.5rem;
  right: 0;
  width: 0.3125rem;
}

.data__info-item {
  flex-flow: row wrap;
  align-items: center;
}

.data__info-item p {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  padding-right: 2.5rem;
}

.data__info-item p > span {
  font-size: 1.4em;
}

.data__info-item p > span > small {
  font-size: 0.75rem;
}

.data__info-item i {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.8em;
  width: 1.8em;
  flex-basis: 1.8em;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 1em;
  font-size: 2em;
}

.data .breadcrumb {
  transform: rotateZ(-90deg);
  right: 80vw;
  bottom: 19rem;
  white-space: nowrap;
}

.contact:hover .grecaptcha-badge {
  transform: translateX(-190px);
}

.contact .grecaptcha-badge {
  position: fixed;
  bottom: 10%;
  left: 0;
  transform: translateX(-100%);
}

.contact .grecaptcha-badge:hover {
  transform: none;
}

.contact__title {
  line-height: 1.1;
}

.contact__title::after {
  content: "";
  width: 0.3125rem;
  right: 0;
}

.contact__footer .btn:not(:disabled) {
  color: #ee3343;
}

.contact .breadcrumb {
  transform: rotateZ(-90deg);
  left: 80vw;
  bottom: 16rem;
  white-space: nowrap;
}

#clgo {
  z-index: 1000 !important;
}

@media (min-width: 37.5em) {
  .header {
    font-size: 0.875rem;
    flex-flow: row nowrap;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;
  }

  .header.Mini {
    font-size: 0.625rem;
    height: 8em;
  }

  .header__logo {
    width: 20em;
  }

  .header__link {
    margin: 0 1em;
    padding-bottom: 0.5em;
    border-bottom: 0.25rem solid transparent;
  }

  .header__link.Current,
  .header__link:hover {
    border-color: currentColor;
  }

  .notification {
    width: 31.25rem;
    left: calc( 50% - 15.625rem);
  }

  .notification > i {
    margin-right: 0.5em;
    font-size: 4.4em;
  }

  .notification h2 {
    margin-bottom: 0.2em;
  }

  .product__model {
    font-size: 0.8em;
  }

  .product-single::after {
    background-color: currentColor;
    height: 0.625rem;
    width: calc( 100% - 11.43674vw);
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .product-single__warranty {
    height: 6em;
    width: 6em;
  }

  .product-single__subtitle {
    font-size: 1.3em;
  }

  .product-single__btns {
    border-top: 1px solid #e9e9e9;
  }

  .product-single__close {
    top: 1rem;
  }

  .Productos .content__body > article h2::before {
    background-size: auto 70%;
    background-position: -20rem center;
  }

  .about__title {
    display: block;
  }

  .about__title::after {
    transform: translateY(-50%);
  }

  .about__main {
    margin: 1.5em 0 3em;
  }

  .about__mkt {
    margin-bottom: 5em;
  }

  .about [drag-horizontal] {
    display: none;
  }

  .contact__footer button {
    margin-top: 0;
  }
}

@media (min-width: 37.5em) and (orientation: portrait) {
  .header {
    font-size: 1.75781vw;
  }
}

@media (min-width: 64.0625em) {
  [columns]:not(:last-child) {
    margin-right: 1rem;
  }

  [columns*='lg'][columns~="1"] {
    width: calc( ( ( 100% - 15rem ) /  16 ) * 1 + ( 0rem ));
    flex-basis: calc( ( ( 100% - 15rem ) /  16 ) * 1 + ( 0rem ));
  }

  [columns*='lg'][columns~="2"] {
    width: calc( ( ( 100% - 15rem ) /  16 ) * 2 + ( 1rem ));
    flex-basis: calc( ( ( 100% - 15rem ) /  16 ) * 2 + ( 1rem ));
  }

  [columns*='lg'][columns~="3"] {
    width: calc( ( ( 100% - 15rem ) /  16 ) * 3 + ( 2rem ));
    flex-basis: calc( ( ( 100% - 15rem ) /  16 ) * 3 + ( 2rem ));
  }

  [columns*='lg'][columns~="4"] {
    width: calc( ( ( 100% - 15rem ) /  16 ) * 4 + ( 3rem ));
    flex-basis: calc( ( ( 100% - 15rem ) /  16 ) * 4 + ( 3rem ));
  }

  [columns*='lg'][columns~="5"] {
    width: calc( ( ( 100% - 15rem ) /  16 ) * 5 + ( 4rem ));
    flex-basis: calc( ( ( 100% - 15rem ) /  16 ) * 5 + ( 4rem ));
  }

  [columns*='lg'][columns~="6"] {
    width: calc( ( ( 100% - 15rem ) /  16 ) * 6 + ( 5rem ));
    flex-basis: calc( ( ( 100% - 15rem ) /  16 ) * 6 + ( 5rem ));
  }

  [columns*='lg'][columns~="7"] {
    width: calc( ( ( 100% - 15rem ) /  16 ) * 7 + ( 6rem ));
    flex-basis: calc( ( ( 100% - 15rem ) /  16 ) * 7 + ( 6rem ));
  }

  [columns*='lg'][columns~="8"] {
    width: calc( ( ( 100% - 15rem ) /  16 ) * 8 + ( 7rem ));
    flex-basis: calc( ( ( 100% - 15rem ) /  16 ) * 8 + ( 7rem ));
  }

  [columns*='lg'][columns~="9"] {
    width: calc( ( ( 100% - 15rem ) /  16 ) * 9 + ( 8rem ));
    flex-basis: calc( ( ( 100% - 15rem ) /  16 ) * 9 + ( 8rem ));
  }

  [columns*='lg'][columns~="10"] {
    width: calc( ( ( 100% - 15rem ) /  16 ) * 10 + ( 9rem ));
    flex-basis: calc( ( ( 100% - 15rem ) /  16 ) * 10 + ( 9rem ));
  }

  [columns*='lg'][columns~="11"] {
    width: calc( ( ( 100% - 15rem ) /  16 ) * 11 + ( 10rem ));
    flex-basis: calc( ( ( 100% - 15rem ) /  16 ) * 11 + ( 10rem ));
  }

  [columns*='lg'][columns~="12"] {
    width: calc( ( ( 100% - 15rem ) /  16 ) * 12 + ( 11rem ));
    flex-basis: calc( ( ( 100% - 15rem ) /  16 ) * 12 + ( 11rem ));
  }

  [columns*='lg'][columns~="13"] {
    width: calc( ( ( 100% - 15rem ) /  16 ) * 13 + ( 12rem ));
    flex-basis: calc( ( ( 100% - 15rem ) /  16 ) * 13 + ( 12rem ));
  }

  [columns*='lg'][columns~="14"] {
    width: calc( ( ( 100% - 15rem ) /  16 ) * 14 + ( 13rem ));
    flex-basis: calc( ( ( 100% - 15rem ) /  16 ) * 14 + ( 13rem ));
  }

  [columns*='lg'][columns~="15"] {
    width: calc( ( ( 100% - 15rem ) /  16 ) * 15 + ( 14rem ));
    flex-basis: calc( ( ( 100% - 15rem ) /  16 ) * 15 + ( 14rem ));
  }

  [columns*='lg'][columns~="16"] {
    width: calc( ( ( 100% - 15rem ) /  16 ) * 16 + ( 15rem ));
    flex-basis: calc( ( ( 100% - 15rem ) /  16 ) * 16 + ( 15rem ));
  }

  [drag-down] {
    display: none;
  }

  label:not([for="newsletter"]) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-flow: row wrap;
  }

  label:not([for="newsletter"]) input,
  label:not([for="newsletter"]) select,
  label:not([for="newsletter"]) textarea,
  label:not([for="newsletter"]) [styled-select] {
    width: 60%;
    flex-basis: 60%;
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 2em;
  }

  .header {
    height: 7.5rem;
  }

  ::-webkit-scrollbar {
    width: 0.625rem;
    height: 0.625rem;
  }

  ::-webkit-scrollbar-track {
    background-color: #e6e6e6;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: #bfbfbf;
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    background-color: #bfbfbf;
  }

  .footer {
    display: flex;
    font-size: 0.6875rem;
  }

  .styled-select__items {
    top: 4em;
  }

  .breadcrumb {
    font-size: 0.75rem;
  }

  .btn.Basic:not(:last-child) {
    margin-right: 1.5em;
  }

  .btn.Basic {
    padding: 0;
    font-size: 0.875em;
  }

  .btn.Basic span {
    color: #777776;
  }

  .btn.Basic i {
    background-color: #ffffff;
    box-shadow: 0 0 0.3em rgba(19, 19, 19, 0.2);
  }

  .btn.Basic:hover span {
    color: currentColor;
  }

  .product {
    border-top: 0.2em solid currentColor;
    padding-top: 0.8em;
  }

  .product:not(:last-child) {
    margin-right: 1rem;
  }

  .product__title {
    font-size: 1.15em;
    width: 13.125rem;
  }

  .product__model::after {
    font-size: 1.3em;
  }

  .product__btns {
    position: absolute;
    top: 100%;
    right: 0.5rem;
    transform: translateY(-60%);
  }

  .product-single {
    padding: 5.71837vw;
    font-size: 1rem;
    display: flex;
    width: 75rem;
    max-width: 100%;
  }

  .product-single__warranty {
    top: 3em;
    left: 3em;
  }

  .product-single__slider {
    width: 25rem;
    flex-basis: 25rem;
    height: 25rem;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 2rem;
  }

  .product-single__imgs-nav .btn {
    font-size: 1em;
  }

  .product-single__imgs-nav .btn i {
    box-shadow: none;
    background: transparent;
  }

  .product-single__imgs-nav > i.Active::after {
    content: "";
    position: absolute;
    width: 0.9em;
    bottom: 0.7em;
    left: 50%;
    transform: translateX(-50%);
    height: 0.25rem;
    background-color: currentColor;
  }

  .product-single__content {
    padding: 1em 0;
    display: flex;
    flex-direction: column;
  }

  .product-single__content svg {
    left: 100%;
    transform: translateX(-50%);
    top: 0;
    width: 4em;
  }

  .product-single__type {
    font-size: 1.2em;
  }

  .product-single__title {
    font-size: 2.6em;
    max-width: 10em;
  }

  .product-single__btns {
    margin-top: auto;
    padding-top: 1em;
  }

  .product-single__close {
    font-size: 1.42959vw;
  }

  .main-slider__item {
    padding: calc(12.5rem) 0 5rem;
    border-bottom: 2.5rem solid currentColor;
  }

  .main-slider__label {
    font-size: 0.8125rem;
  }

  .main-slider__subtitle {
    font-size: 1.125rem;
    max-width: 25em;
  }

  .main-slider__caracts {
    display: flex;
    margin-bottom: 3em;
  }

  .main-slider__btns {
    border-top: 1px solid currentColor;
    padding-top: 1em;
  }

  .main-slider > .btn {
    font-size: 2em;
    bottom: 3px;
    transform: translateX(-50%);
  }

  .main-slider > .btn [drag-horizontal] {
    display: none;
  }

  .main-slider__nav {
    font-size: 2em;
    bottom: 7.5rem;
  }

  .content__foot {
    text-align: right;
  }

  .Productos::before {
    width: calc( ( 100vw - 82.5rem ) / 2 + 10vw);
    height: calc( 100% - 18.75rem);
  }

  .Productos .content__foot {
    margin-top: 3.33333rem;
  }

  .Productos .content__body {
    display: flex;
  }

  .Productos .content__body > article {
    align-self: flex-end;
    padding-right: calc( ( ( 100% - 15rem ) /  16 ) * 1 + ( 0rem ));
    width: 25rem;
    flex-basis: 25rem;
    flex-shrink: 0;
  }

  .Productos .content__body > article h2 span {
    display: block;
  }

  .Productos .content__body [drag-horizontal] {
    font-size: 2.5em;
  }

  .Productos .dragscroll {
    overflow-x: scroll;
    overflow-y: hidden;
    padding-top: 1em;
    padding-bottom: 3em;
  }

  .Productos .dragscroll:hover {
    cursor: grab;
  }

  .Productos .dragscroll:active {
    cursor: grabbing;
  }

  .Productos .products__row:not(:last-child) {
    margin-bottom: 2.5rem;
  }

  .about__title {
    font-size: 4em;
  }

  .about__title::after {
    height: 0.8em;
    width: 0.8em;
    right: 5rem;
  }

  .about__main {
    font-size: 1.5em;
    width: calc( ( ( 100% - 15rem ) /  16 ) * 8 + ( 7rem ));
  }

  .about__mkt-item {
    width: calc( ( ( 100% - 15rem ) /  16 ) * 5 + ( 4rem ));
    flex-basis: calc( ( ( 100% - 15rem ) /  16 ) * 5 + ( 4rem ));
    padding-right: calc( ( ( 100% - 15rem ) /  16 ) * 1 + ( 0rem ));
  }

  .about__mkt-title {
    font-size: 1.5em;
  }

  .about__mkt-desc {
    font-size: 0.9em;
  }

  .about .btn i::before {
    color: #ee3343;
  }

  .about .btn span {
    color: #ffffff;
  }

  .data {
    align-items: flex-end;
    border-bottom: 2px solid currentColor;
  }

  .data__info {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 40%;
    padding: 7.5rem 0;
  }

  .data__info-item:first-child::after,
  .data__info-item:nth-child(3)::after {
    content: "";
    display: block;
    width: 100%;
    background-color: #e9e9e9;
    flex-grow: 1;
    height: 1px;
    margin: 3em 0;
  }

  .data__info-item:nth-child(2),
  .data__info-item:nth-child(4) {
    margin-bottom: 1em;
  }

  .data__map {
    min-height: 31.25rem;
    height: 100vh;
    max-height: 50rem;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 60vw;
    width: 60vw;
  }

  .contact.content {
    padding-bottom: 5rem;
  }

  .contact__title::after {
    top: 5rem;
    height: 75%;
  }

  .contact__title {
    width: 12em;
    flex-basis: 12em;
    margin-left: 6.5rem;
    padding-right: 1vw;
    display: flex;
    flex-flow: column nowrap;
  }

  .contact__title span {
    color: #777776;
    margin-top: auto;
    font-size: 0.5em;
    max-width: 15em;
  }

  .contact__title i {
    color: #ee3343;
    display: block;
    font-size: 2em;
    margin-bottom: 1em;
  }

  .contact__title b {
    color: #ee3343;
  }

  .contact__form {
    text-align: right;
    flex-grow: 1;
    max-width: calc( ( ( 100% - 15rem ) /  16 ) * 7 + ( 6rem ));
  }

  .contact__form hr {
    background-color: #e9e9e9;
    position: relative;
    width: 200vw;
    border: none;
    height: 1px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    margin: 1em 0;
  }
}

@media (orientation: landscape) and (min-width: 64.0625em) {
  .main-slider__inner {
    height: 55vh;
    min-height: 25rem;
    max-height: 31.25rem;
  }

  .main-slider__content svg {
    width: 4.0625rem;
    left: 100%;
  }

  .main-slider__title {
    font-size: 3.125rem;
    max-width: 11em;
  }
}

@media (orientation: portrait) and (min-width: 64.0625em) {
  .main-slider__title {
    font-size: 3.57398vw;
  }
}

@media (min-width: 87.5em) {
  .header,
  .footer,
  .main-slider__inner,
  .content,
  .Productos,
  .data__info {
    padding-left: calc( ( 100% - 82.5rem ) / 2);
  }

  .header,
  .footer,
  .main-slider__inner,
  .content {
    padding-right: calc( ( 100% - 82.5rem ) / 2);
  }

  label:not([for="newsletter"]) {
    font-size: 0.8125rem;
  }

  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-slider__nav {
    right: calc( ( 100% - 87.4375rem ) / 2);
    transform: translateX(2vw);
  }

  .main-slider .slick-dots {
    left: calc( ( 100% - 87.4375rem ) / 2);
    transform: translateX(-2vw);
  }

  .content {
    padding-top: 5rem;
    padding-bottom: 2.5rem;
  }

  .Productos .content__body > article h2 {
    font-size: 3.375rem;
  }

  .Productos .content__body > article p {
    font-size: 0.875rem;
  }

  .about {
    padding-bottom: 7.5rem;
    padding-top: 7.5rem;
  }

  .data__info-item {
    font-size: 0.8125rem;
  }

  .contact__title {
    font-size: 2.625rem;
  }
}

@media (max-width: 87.49em) {
  .header,
  .footer,
  .main-slider__inner,
  .content,
  .Productos,
  .data__info {
    padding-left: 7vw;
  }

  .header,
  .footer,
  .main-slider__inner,
  .content {
    padding-right: 7vw;
  }

  .data .breadcrumb {
    display: none;
  }

  .contact .breadcrumb {
    display: none;
  }
}

@media (max-width: 64.0525em) {
  [scroll] {
    display: none;
  }

  .footer {
    font-size: 0.8em;
  }

  .footer__copyright-item:last-child {
    position: absolute;
    left: -999vw;
  }

  .btn.Basic {
    border-radius: 10em;
    background-color: currentColor;
    overflow: hidden;
    padding-left: 0.5em;
    padding-right: 2em;
    height: 3em;
  }

  .btn.Basic:hover span {
    opacity: 0.6;
  }

  .btn.Basic i {
    color: #ffffff;
  }

  .btn.Basic span {
    color: #ffffff;
  }

  .btn.Basic {
    background-color: currentColor;
    border-radius: 50em;
    font-size: 0.75em;
  }

  .product:not(:last-child) {
    margin-right: 2em;
  }

  .product:last-child {
    padding-right: 10em;
  }

  .product__model::after {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2em;
    width: 2em;
    border-radius: 50%;
    font-size: 1.5em;
    box-shadow: 0 0 0.3em rgba(19, 19, 19, 0.2);
  }

  .product__actions {
    display: none;
  }

  .product-single {
    padding: 2.5em;
  }

  .product-single__imgs-nav > i {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5em;
    width: 2.5em;
    font-size: 1em;
    border-radius: 50%;
    margin-bottom: 0.5em;
  }

  .product-single__imgs-nav > i.Active {
    background-color: currentColor;
  }

  .product-single__imgs-nav > i.Active::before {
    color: #ffffff;
  }

  .product-single__img {
    padding: 1em 0 1.5em;
  }

  .product-single__content {
    margin-top: 2em;
  }

  .product-single__btns {
    text-align: center;
    padding-top: 1.2em;
  }

  .product-single__btns [download] {
    display: none;
  }

  .main-slider__item {
    border-bottom: 0.625rem solid currentColor;
  }

  .main-slider__imgs-nav {
    display: none;
  }

  .main-slider__caracts {
    display: none;
  }

  .main-slider__btns {
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .main-slider__btns button.btn,
  .main-slider__btns a.btn {
    flex-grow: 1;
    flex-basis: 100%;
    margin-right: 0;
    margin-top: 1em;
  }

  .main-slider__btns button.btn[download],
  .main-slider__btns a.btn[download] {
    display: none;
  }

  .main-slider > .btn {
    top: 100%;
    transform: translate(-50%, -60%);
  }

  .main-slider > .btn [scroll] {
    display: none;
  }

  .main-slider__nav {
    display: none;
  }

  .main-slider .slick-dots {
    display: none !important;
  }

  .content__head {
    display: none;
  }

  .content__foot::before {
    margin-bottom: 2em;
  }

  .content__foot {
    text-align: center;
    font-size: 1.2em;
  }

  .content__foot .btn:last-child {
    background-color: #ffffff;
    border: 0.125rem solid #ee3343;
    color: #ee3343;
  }

  .content__foot .btn:last-child span,
  .content__foot .btn:last-child i {
    color: currentColor;
  }

  .Productos .content__body > article h2::after {
    background-color: #e9e9e9;
    content: "";
    display: block;
    position: relative;
    width: 100vw;
    transform: translateX(-50%);
    left: 50%;
    height: 1px;
    margin-top: 1.5rem;
    margin-top: 0.7em;
  }

  .Productos .content__body > article p {
    display: none;
  }

  .Productos .content__body [drag-horizontal] {
    font-size: 2em;
  }

  .Productos .products__row {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .Productos .products__row:not(:last-child) {
    position: relative;
    border-bottom: 1px solid #e9e9e9;
  }

  .about .btn {
    display: none;
  }

  .data {
    flex-flow: column nowrap;
    align-items: center;
  }

  .data__info {
    order: 1;
  }

  .data__info {
    box-shadow: 0 0 0.3em rgba(19, 19, 19, 0.2);
    width: calc(100% - 10vw);
    margin-top: -5em;
    display: flex;
    flex-flow: row wrap;
    width: calc(100vw - 10vw);
    flex-grow: 0;
    flex-shrink: 0;
  }

  .data__info-item {
    font-size: 1.46484vw;
  }

  .data__info-item:not(:first-child) {
    flex-basis: auto;
    margin-bottom: 1em;
    flex-grow: 1;
    flex-shrink: 1;
  }

  .data__info-item:nth-child(3),
  .data__info-item:nth-child(5) {
    flex-basis: 60%;
  }

  .data__info-item:first-child {
    display: block;
    border-bottom: 1px solid #e9e9e9;
    text-align: center;
    font-size: 2em;
    color: #ee3343;
    font-family: 'Raleway', 'Helvetica', 'Arial', sans-serif;
    padding: 0.7em 0 0.6em;
    position: absolute;
    left: 0;
    bottom: 100%;
    background-color: inherit;
    width: 100%;
  }

  .data__info-item:first-child::before {
    content: "Estamos en";
  }

  .data__info-item:first-child::after {
    content: " Villa Domínico";
    font-weight: 700;
  }

  .data__info-item:first-child p,
  .data__info-item:first-child span {
    display: none;
  }

  .data__info-item:first-child i {
    display: none;
  }

  .data__map {
    width: 100%;
    height: 40rem;
    flex-basis: 100%;
    flex-shrink: 0;
    flex-grow: 0;
    border-bottom: 0.375rem solid #ee3343;
  }

  .contact {
    flex-flow: column nowrap;
    padding-top: 3.33333rem;
  }

  .contact.content {
    padding-top: 3.33333rem;
    padding-bottom: 5rem;
  }

  .contact__title {
    font-size: 7vw;
    max-width: 12em;
  }

  .contact__title span {
    display: none;
  }

  .contact__form {
    order: 1;
    margin-top: 2.5em;
  }

  .contact__form::before {
    background-color: #e9e9e9;
    content: "";
    display: block;
    position: relative;
    width: 100vw;
    transform: translateX(-50%);
    left: 50%;
    height: 1px;
    margin-bottom: 1.5rem;
  }

  .contact__form-inner {
    margin-top: 2.5em;
  }

  .contact__form-inner label {
    margin-bottom: 1.5em;
    display: block;
  }

  .contact__form hr {
    display: none;
  }
}

@media (orientation: portrait) and (max-width: 64.0525em) {
  .main-slider__content svg {
    width: 10vw;
    right: 0;
    top: 1em;
  }
}

@media (orientation: landscape) and (max-width: 64.0525em) {
  .main-slider__content svg {
    left: 100%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    fill: rgba(19, 19, 19, 0.05);
  }
}

@media (max-width: 37.49em) {
  [columns]:not(:last-child) {
    margin-right: 1rem;
  }

  [columns*='"sm": 1'] {
    width: calc( ( ( 100% - 3rem ) /  4 ) * 1 + ( 0rem ));
    flex-basis: calc( ( ( 100% - 3rem ) /  4 ) * 1 + ( 0rem ));
  }

  [columns*='"sm": 2'] {
    width: calc( ( ( 100% - 3rem ) /  4 ) * 2 + ( 1rem ));
    flex-basis: calc( ( ( 100% - 3rem ) /  4 ) * 2 + ( 1rem ));
  }

  [columns*='"sm": 3'] {
    width: calc( ( ( 100% - 3rem ) /  4 ) * 3 + ( 2rem ));
    flex-basis: calc( ( ( 100% - 3rem ) /  4 ) * 3 + ( 2rem ));
  }

  [columns*='"sm": 4'] {
    width: calc( ( ( 100% - 3rem ) /  4 ) * 4 + ( 3rem ));
    flex-basis: calc( ( ( 100% - 3rem ) /  4 ) * 4 + ( 3rem ));
  }

  [columns*='"sm": 5'] {
    width: calc( ( ( 100% - 3rem ) /  4 ) * 5 + ( 4rem ));
    flex-basis: calc( ( ( 100% - 3rem ) /  4 ) * 5 + ( 4rem ));
  }

  [columns*='"sm": 6'] {
    width: calc( ( ( 100% - 3rem ) /  4 ) * 6 + ( 5rem ));
    flex-basis: calc( ( ( 100% - 3rem ) /  4 ) * 6 + ( 5rem ));
  }

  [columns*='"sm": 7'] {
    width: calc( ( ( 100% - 3rem ) /  4 ) * 7 + ( 6rem ));
    flex-basis: calc( ( ( 100% - 3rem ) /  4 ) * 7 + ( 6rem ));
  }

  [columns*='"sm": 8'] {
    width: calc( ( ( 100% - 3rem ) /  4 ) * 8 + ( 7rem ));
    flex-basis: calc( ( ( 100% - 3rem ) /  4 ) * 8 + ( 7rem ));
  }

  [columns*='"sm": 9'] {
    width: calc( ( ( 100% - 3rem ) /  4 ) * 9 + ( 8rem ));
    flex-basis: calc( ( ( 100% - 3rem ) /  4 ) * 9 + ( 8rem ));
  }

  [columns*='"sm": 10'] {
    width: calc( ( ( 100% - 3rem ) /  4 ) * 10 + ( 9rem ));
    flex-basis: calc( ( ( 100% - 3rem ) /  4 ) * 10 + ( 9rem ));
  }

  [columns*='"sm": 11'] {
    width: calc( ( ( 100% - 3rem ) /  4 ) * 11 + ( 10rem ));
    flex-basis: calc( ( ( 100% - 3rem ) /  4 ) * 11 + ( 10rem ));
  }

  [columns*='"sm": 12'] {
    width: calc( ( ( 100% - 3rem ) /  4 ) * 12 + ( 11rem ));
    flex-basis: calc( ( ( 100% - 3rem ) /  4 ) * 12 + ( 11rem ));
  }

  [columns*='"sm": 13'] {
    width: calc( ( ( 100% - 3rem ) /  4 ) * 13 + ( 12rem ));
    flex-basis: calc( ( ( 100% - 3rem ) /  4 ) * 13 + ( 12rem ));
  }

  [columns*='"sm": 14'] {
    width: calc( ( ( 100% - 3rem ) /  4 ) * 14 + ( 13rem ));
    flex-basis: calc( ( ( 100% - 3rem ) /  4 ) * 14 + ( 13rem ));
  }

  [columns*='"sm": 15'] {
    width: calc( ( ( 100% - 3rem ) /  4 ) * 15 + ( 14rem ));
    flex-basis: calc( ( ( 100% - 3rem ) /  4 ) * 15 + ( 14rem ));
  }

  [columns*='"sm": 16'] {
    width: calc( ( ( 100% - 3rem ) /  4 ) * 16 + ( 15rem ));
    flex-basis: calc( ( ( 100% - 3rem ) /  4 ) * 16 + ( 15rem ));
  }

  .header {
    font-size: 3.67279vw;
    flex-flow: column nowrap;
    justify-content: center;
    padding-top: 1.5em;
    padding-left: 0;
    padding-right: 0;
  }

  .header.Mini {
    font-size: 0.75rem;
    transform: translateY(calc( -100% + 2.5rem));
  }

  .header__logo {
    width: 15em;
    margin-bottom: 1.5em;
  }

  .header__nav {
    align-items: center;
    background-color: currentColor;
    height: 2.5rem;
    width: 100%;
    justify-content: center;
  }

  .header__link {
    margin: 0 0.5em;
    color: #ffffff;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .header__link[href="#top"] {
    display: none;
  }

  .header__link.Current::after,
  .header__link:hover::after {
    content: "";
    position: absolute;
    display: block;
    background-color: white;
    height: 0.125rem;
    width: 100%;
    bottom: 0;
  }

  .notification {
    font-size: 0.7em;
    text-align: center;
    width: 90%;
    max-width: 25em;
    transform: translateX(-50%);
    left: calc( 50% - 25em/2);
  }

  .notification > i {
    margin-right: 0.2em;
    font-size: 3.5em;
  }

  .notification p {
    display: none;
  }

  .product__title {
    font-size: 1.2em;
    width: 10em;
  }

  .product__model {
    font-size: 0.7em;
  }

  .modal.content {
    padding: 1em 0.5em 2em;
  }

  .product-single {
    padding: 1.3em 1.3rem 0;
  }

  .product-single__warranty {
    height: 4em;
    width: 4em;
    top: 0.5em;
    left: 0.5em;
  }

  .product-single__imgs-nav .btn {
    font-size: 1.5rem;
    position: absolute;
    bottom: 7.8125rem;
  }

  .product-single__imgs-nav .btn:first-of-type {
    transform: translate(-50%, calc(50% + 0.2rem));
    left: 0;
  }

  .product-single__imgs-nav .btn:last-of-type {
    transform: translate(50%, calc(50% + 0.2rem));
    right: 0;
  }

  .product-single__img img {
    height: 12.5rem;
  }

  .product-single__content svg {
    display: none;
  }

  .product-single__title {
    font-size: 7vw;
    max-width: 11em;
  }

  .product-single__subtitle {
    background-color: whitesmoke;
    margin: 1.5em -1.3rem 1.5em;
    padding: 1em 1.5rem;
    font-size: 1.1em;
  }

  .product-single__btns {
    background-color: whitesmoke;
    padding: 1.5rem;
    margin: 0 -1.3rem;
  }

  .product-single__close {
    bottom: 1rem;
    font-size: 1.5rem;
  }

  .main-slider__item {
    padding: 7.5rem 0 2.5rem;
  }

  .main-slider__label {
    display: none;
  }

  .main-slider__subtitle {
    display: none;
  }

  .main-slider__btns {
    font-size: 1.1em;
    font-size: 2.5vmax;
  }

  .main-slider > .btn {
    font-size: 1.5em;
  }

  .content {
    padding-top: 4.16667rem;
    padding-bottom: 2.5rem;
  }

  .content__foot {
    font-size: 1.1em;
  }

  .Productos .content__foot {
    margin-top: 1rem;
  }

  .Productos .content__body > article h2 {
    font-size: 3em;
    font-size: 7vmax;
  }

  .Productos .content__body > article h2::first-letter {
    text-transform: uppercase;
  }

  .Productos .content__body > article h2::before {
    background-size: auto 40%;
    background-position: -10rem center;
  }

  .Productos .content__body > article h2 span {
    display: none;
  }

  .Productos .products__row {
    padding: 1.66667rem 0 1.25rem;
  }

  .about {
    padding-bottom: 1rem;
    padding-top: 3.33333rem;
  }

  .about__title {
    font-size: 3em;
    font-size: 7vmax;
    display: inline-block;
  }

  .about__title::after {
    height: 0.7em;
    width: 0.7em;
    left: 100%;
    transform: translate(20%, -130%);
  }

  .about__main {
    font-size: 1.1em;
    margin: 1.5em 0 2em;
  }

  .about__mkt {
    overflow: scroll;
  }

  .about__mkt-item {
    background: #ffffff;
    color: #ee3343;
    padding: 2em 1.5em;
    flex-basis: calc(100% - 2em);
    flex-shrink: 0;
    max-width: 23em;
  }

  .about__mkt-item:not(:last-child) {
    margin-right: 1em;
  }

  .about__mkt-title {
    font-size: 1.2em;
  }

  .about__mkt-desc {
    font-size: 0.8em;
  }

  .data__info {
    padding: 5vw;
  }

  .data__info-item {
    font-size: 2.17028vw;
  }

  .data__info-item:first-child {
    font-size: 5.00835vw;
  }

  .contact__footer button {
    width: 100%;
    margin-top: 2em;
  }

  .contact__footer [for="newsletter"] {
    font-size: 3.00501vw;
  }
}

@media (orientation: portrait) and (max-width: 37.49em) {
  .main-slider__inner {
    height: 75vh;
  }

  .main-slider__title {
    font-size: 9.18197vw;
  }

  .main-slider__imgs {
    height: 70vw;
  }
}

@media (orientation: landscape) and (max-width: 37.49em) {
  .main-slider__inner {
    height: 40vw;
  }

  .main-slider__title {
    font-size: 6.6778vw;
  }
}

@media (max-width: 37.49em) and (orientation: landscape) {
  .content__foot .btn:not(:last-child) {
    margin-right: 1em;
  }
}

@media (max-width: 37.49em) and (orientation: portrait) {
  .content__foot .btn {
    width: 100%;
  }

  .content__foot .btn:not(:last-child) {
    margin-bottom: 1em;
  }
}

@media (min-width: 37.5em) and (max-width: 64.0525em) {
  [columns]:not(:last-child) {
    margin-right: 1rem;
  }

  [columns*='"md": 1'] {
    width: calc( ( ( 100% - 7rem ) /  8 ) * 1 + ( 0rem ));
    flex-basis: calc( ( ( 100% - 7rem ) /  8 ) * 1 + ( 0rem ));
  }

  [columns*='"md": 2'] {
    width: calc( ( ( 100% - 7rem ) /  8 ) * 2 + ( 1rem ));
    flex-basis: calc( ( ( 100% - 7rem ) /  8 ) * 2 + ( 1rem ));
  }

  [columns*='"md": 3'] {
    width: calc( ( ( 100% - 7rem ) /  8 ) * 3 + ( 2rem ));
    flex-basis: calc( ( ( 100% - 7rem ) /  8 ) * 3 + ( 2rem ));
  }

  [columns*='"md": 4'] {
    width: calc( ( ( 100% - 7rem ) /  8 ) * 4 + ( 3rem ));
    flex-basis: calc( ( ( 100% - 7rem ) /  8 ) * 4 + ( 3rem ));
  }

  [columns*='"md": 5'] {
    width: calc( ( ( 100% - 7rem ) /  8 ) * 5 + ( 4rem ));
    flex-basis: calc( ( ( 100% - 7rem ) /  8 ) * 5 + ( 4rem ));
  }

  [columns*='"md": 6'] {
    width: calc( ( ( 100% - 7rem ) /  8 ) * 6 + ( 5rem ));
    flex-basis: calc( ( ( 100% - 7rem ) /  8 ) * 6 + ( 5rem ));
  }

  [columns*='"md": 7'] {
    width: calc( ( ( 100% - 7rem ) /  8 ) * 7 + ( 6rem ));
    flex-basis: calc( ( ( 100% - 7rem ) /  8 ) * 7 + ( 6rem ));
  }

  [columns*='"md": 8'] {
    width: calc( ( ( 100% - 7rem ) /  8 ) * 8 + ( 7rem ));
    flex-basis: calc( ( ( 100% - 7rem ) /  8 ) * 8 + ( 7rem ));
  }

  [columns*='"md": 9'] {
    width: calc( ( ( 100% - 7rem ) /  8 ) * 9 + ( 8rem ));
    flex-basis: calc( ( ( 100% - 7rem ) /  8 ) * 9 + ( 8rem ));
  }

  [columns*='"md": 10'] {
    width: calc( ( ( 100% - 7rem ) /  8 ) * 10 + ( 9rem ));
    flex-basis: calc( ( ( 100% - 7rem ) /  8 ) * 10 + ( 9rem ));
  }

  [columns*='"md": 11'] {
    width: calc( ( ( 100% - 7rem ) /  8 ) * 11 + ( 10rem ));
    flex-basis: calc( ( ( 100% - 7rem ) /  8 ) * 11 + ( 10rem ));
  }

  [columns*='"md": 12'] {
    width: calc( ( ( 100% - 7rem ) /  8 ) * 12 + ( 11rem ));
    flex-basis: calc( ( ( 100% - 7rem ) /  8 ) * 12 + ( 11rem ));
  }

  [columns*='"md": 13'] {
    width: calc( ( ( 100% - 7rem ) /  8 ) * 13 + ( 12rem ));
    flex-basis: calc( ( ( 100% - 7rem ) /  8 ) * 13 + ( 12rem ));
  }

  [columns*='"md": 14'] {
    width: calc( ( ( 100% - 7rem ) /  8 ) * 14 + ( 13rem ));
    flex-basis: calc( ( ( 100% - 7rem ) /  8 ) * 14 + ( 13rem ));
  }

  [columns*='"md": 15'] {
    width: calc( ( ( 100% - 7rem ) /  8 ) * 15 + ( 14rem ));
    flex-basis: calc( ( ( 100% - 7rem ) /  8 ) * 15 + ( 14rem ));
  }

  [columns*='"md": 16'] {
    width: calc( ( ( 100% - 7rem ) /  8 ) * 16 + ( 15rem ));
    flex-basis: calc( ( ( 100% - 7rem ) /  8 ) * 16 + ( 15rem ));
  }

  .header {
    height: 6.25rem;
  }

  .btn.Basic:not(:last-child) {
    margin-right: 1em;
  }

  .product__title {
    font-size: 1.4em;
    width: 17.5rem;
  }

  .product-single__warranty {
    top: 1em;
    left: 1em;
  }

  .product-single__imgs-nav .btn {
    font-size: 3em;
    position: absolute;
    bottom: 7.5rem;
    right: 0;
  }

  .product-single__imgs-nav .btn:first-of-type {
    transform: translate(50%, calc(-50% - 0.2rem));
  }

  .product-single__imgs-nav .btn:last-of-type {
    transform: translate(50%, calc(50% + 0.2rem));
  }

  .product-single__img img {
    height: 18.75rem;
  }

  .product-single__content svg {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 12vw;
    width: 12vw;
    background-color: whitesmoke;
    right: 0;
    padding: 1.2em;
    border-radius: 50%;
  }

  .product-single__title {
    font-size: 6vw;
    max-width: 11em;
  }

  .main-slider__item {
    padding: calc( 8.75rem) 0 5rem;
  }

  .main-slider__label {
    font-size: 1.75781vw;
  }

  .main-slider__subtitle {
    font-size: 2.24609vw;
  }

  .main-slider__btns {
    font-size: 1.1em;
  }

  .main-slider > .btn {
    font-size: 2.1em;
  }

  .content {
    padding-top: 5rem;
    padding-bottom: 2.5rem;
  }

  .content__foot {
    font-size: 1.2em;
  }

  .Productos::before {
    width: calc( ( 100vw - 54.0625rem ) / 2 + 10vw);
    height: calc( 100% - 23.125rem);
  }

  .Productos .content__foot {
    margin-top: 2.5rem;
  }

  .Productos .content__body > article h2 {
    font-size: 4em;
    font-size: 6vmax;
  }

  .Productos .products {
    padding-top: 2.5rem;
  }

  .Productos .products__row {
    padding: 2.5rem 0;
  }

  .about {
    padding-bottom: 1rem;
    padding-top: 5rem;
  }

  .about__title {
    font-size: 4em;
    font-size: 6vmax;
  }

  .about__title::after {
    height: 1.5em;
    width: 1.5em;
    right: 0;
  }

  .about__main {
    font-size: 1.5em;
    width: 25em;
    max-width: 100%;
  }

  .about__mkt {
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .about__mkt-item {
    flex-basis: 20rem;
    margin-bottom: 2em;
    margin-right: 2em;
    flex-grow: 1;
  }

  .about__mkt-title {
    font-size: 1.6em;
  }

  .about__mkt-desc {
    font-size: 1.2em;
  }

  .data__info {
    padding: 2em 3em;
  }

  .contact__title::after {
    bottom: 2.5rem;
    height: 55%;
  }

  .contact__form-inner {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .contact__form-inner label {
    flex-basis: 100%;
  }

  .contact__form-inner label:not(:last-child) {
    flex-basis: calc(50% - 0.5em);
    margin-bottom: 1em;
  }

  .contact__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media (min-width: 64.0625em) and (max-width: 87.49em) {
  label:not([for="newsletter"]) {
    font-size: 0.8935vw;
  }

  .main-slider__nav {
    right: 1em;
  }

  .main-slider .slick-dots {
    left: 1em;
  }

  .content {
    padding-top: 8.57756vw;
    padding-bottom: 6.86204vw;
  }

  .Productos .content__body > article h2 {
    font-size: 3.8599vw;
  }

  .Productos .content__body > article p {
    font-size: 1.21515vw;
  }

  .about {
    padding-bottom: 11.43674vw;
    padding-top: 11.43674vw;
  }

  .data__info-item {
    font-size: 1.07219vw;
  }

  .contact__title {
    font-size: 3.00214vw;
  }
}

@media (orientation: portrait) and (min-width: 37.5em) and (max-width: 64.0525em) {
  .main-slider__inner {
    height: calc( 80vh - 6.25rem);
  }

  .main-slider__title {
    font-size: 7.8125vw;
    max-width: 10em;
  }

  .main-slider__imgs {
    height: 60vw;
  }
}

@media (orientation: landscape) and (min-width: 37.5em) and (max-width: 64.0525em) {
  .main-slider__inner {
    height: 40vw;
  }

  .main-slider__title {
    font-size: 4.88281vw;
  }
}

@media (orientation: landscape) and (min-width: 64.0625em) and (max-width: 87.49em) {
  .main-slider__title {
    font-size: 3.57398vw;
  }
}

@media (orientation: portrait) {
  .main-slider__inner {
    flex-flow: column nowrap;
  }

  .main-slider__content {
    width: 100%;
  }

  .main-slider__content:first-child {
    order: 1;
  }
}

@media (orientation: landscape) {
  .main-slider__content {
    height: 100%;
    width: 50%;
    flex-basis: 50%;
    flex-shrink: 0;
  }
}