//===================================================
//  TIPOGRAFÍAS Y ESTILOS TIPOGRÁFICOS BÁSICOS
//===================================================


[scroll] {
    svg {
        @extend %scroll;
        width: 0.7em;
    }
    
    
    @include mq( $until: lg ) {
        display: none;
    }

}

[drag-down] {
    svg {
        width: 0.7em;
    }
        
    @include mq( $from: lg ) {
        display: none;
    }
}

[drag-horizontal] {
    svg {
        @extend %drag_horizontal;
        width: 0.8em;
        transform: translateX(-5%);

    }
}
