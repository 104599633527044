
*,
*::before,
*::after {
    box-sizing: border-box;
}

::selection {
    @extend %brand_bg;
    @extend %white;
}

main {
    @extend %transition;

    &[v-cloak] {
        opacity: 0;
    }
}

body {
    overflow: hidden;

    &:not(.pace-done) .pace {

        @include before {
            opacity: 1;
        }
    }
}

.pace-running {
    overflow: hidden;
    height: 100vh;
    width: 100vw;

    .pace {
        opacity: 1 !important;
    }

}

.pace {
    @extend %z_goku;
    @extend %transition;
    @include size(100%);
    pointer-events: none;
    user-select: none;
    position: fixed;
    opacity: 0;
    top: 0;
    left: 0;

    
}

.pace-inactive {
    opacity: 0;
}


.pace .pace-progress {
    @extend %brand_bg;
    position: fixed;
    z-index: 2000;
    top: 0;
    right: 100%;
    width: 100%;
    height: 3px;
}


@keyframes loader {
    0%  {
        opacity: 1;
        transform: rotateY(0);
    }
    40%  {
        opacity: 0.8; transform: rotateY(360deg);
    }
    100%  {
         opacity: 1; transform: rotateY(0);
     }
}

[data-before]::before {
    content: attr(data-before);
}

[data-after]::after {
    content: attr(data-after);
}


a, button {
    @extend %transition;

    &:active {
        transform: scale(0.95);
    }

}
