.modal { // revisar
    @extend %full;
    @extend %black_bg_t;
    position: fixed;
    z-index: 1001;
    top: 0;
    left: 0;
    background-image: url("../img/pattern.svg");
    background-size: cover;
    max-height: 100vh;
    overflow-y: scroll;


    @include mq( $until: md ) {
        &.content {
            padding: 1em 0.5em 2em;
        }
    }
        
    @include mq( $from: md, $until: lg ) {
        
    }
        
    @include mq( $from: xl ) {
        @include center_contents;
    }
}

.product-single {
    @extend %white_bg;
    @extend %relative;
    box-shadow: 0 0 2em rgba(c(black), 0.3);

    @include mq( $until: md ) {
        padding: 1.3em 1.3rem 0;
    }
        
    @include mq( $until: lg ) {
        padding: 2.5em;
    }
        
    @include mq( $from: lg ) {
        padding: vw($MODULE, $LG);
        font-size: r(16);
        display: flex;
        width: r(1200);
        max-width: 100%;
    }

    @include after {
        @extend %absolute;
        @extend %block;
            
        @include mq( $from: md ) {
            background-color: currentColor;
            height: r(10);
            width: calc( 100% - #{vw($MODULE*2, $LG)} );
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
        
    }


    [class*="slick"] {
        outline: none;
    }


    // ================================================================
    // WARRANTY
    // ================================================================

    &__warranty {
        @extend %absolute;
        @extend %z_gotten;
        
        fill: currentColor;
        

        @include mq( $until: md ) {
            @include size(4em);
            top: 0.5em;
            left: 0.5em;
        }

        @include mq( $from: md ) {
            @include size(6em);
        }

        @include mq( $from: md, $until: lg ) {
            top: 1em;
            left: 1em;
        }
            
        @include mq( $from: lg ) {
            top: 3em;
            left: 3em;
        }
    }


    // ================================================================
    // SLIDER
    // ================================================================

    &__slider {
        @extend %relative;

        @include mq( $from: lg ) {
            width: r($MODULE*5);
            flex-basis: r($MODULE*5);
            height: r($MODULE*5);
            flex-grow: 0;
            flex-shrink: 0;
            margin-right: r($BASE_FONT_SIZE*2);
        }
        
    }

    &__imgs {
        @extend %txt_body_bg_lt;
        @include center_contents;

        *:not(img) {
            @include size(100%);
        }

        // img {
        //     width: 80%;
        // }
        &-nav {
            @extend %full_width;
            @extend %absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: r(13);
            bottom: 0;


            .btn {
                @include mq( $until: md ) {
                    font-size: 1.5rem;
                    position: absolute;
                    bottom: r(125);
                    

                    &:first-of-type {
                        transform: translate(-50%, calc(50% + 0.2rem));
                        left: 0;
                        
                    }

                    &:last-of-type {
                        transform: translate(50%, calc(50% + 0.2rem));
                        right: 0;
                    }
                }
                    
                @include mq( $from: md, $until: lg ) {
                    font-size: 3em;
                    position: absolute;
                    bottom: r(120);
                    right: 0;

                    &:first-of-type {
                        transform: translate(50%, calc(-50% - 0.2rem));
                    }

                    &:last-of-type {
                        transform: translate(50%, calc(50% + 0.2rem));
                    }
                    
                }
                    
                @include mq( $from: lg ) {
                    font-size: 1em;
                   
                    i {
                        box-shadow: none;
                        background: transparent;
                    }
                }
            }

            > i {
                @extend %relative;
                @extend %pointer;
                @extend %block;
                padding: 1em 0.6em;

                &:not(.fa) {
                    @extend %body_m;
                    font-size: 1.2em;
                }

                @include mq( $until: lg ) {
                    @include center_contents;
                    @include size(2.5em);
                    font-size: 1em;
                    border-radius: 50%;
                    margin-bottom: 0.5em;
                }

                &[data-slider-action*="slickGoTo"]:not(.Active) {
                    opacity: 0.6;
                }
                    
                &.Active {
                        
                    @include mq( $until: lg ) {
                        
                        background-color: currentColor;

                        &::before {
                            color: c(white);
                        }
                    }
                        
                    @include mq( $from: lg ) {

                        @include after {
                            position: absolute;
                            width: 0.9em;
                            bottom: 0.7em;
                            left: 50%;
                            transform: translateX(-50%);
                            height: r(4);
                            background-color: currentColor;
                        }

                    }
                    
                }
            }

            
        }
    }

    &__img {
        @include center_contents;

        @include mq( $until: md ) {
            
        }
            
        @include mq( $from: md, $until: lg ) {
            
        }
            
        @include mq( $until: lg ) {
            padding: 1em 0 1.5em;
        }

        
        
        img {
            width: auto;
            margin: 0 auto;

            @include mq( $until: md ) {
                height: r(200);
            }
                

            @include mq( $from: md, $until: lg ) {
                height: r(300);
            }
            
                
        }
    }

    // ================================================================
    // CONTENT
    // ================================================================

    &__content {
        @extend %relative;

        @include mq( $until: lg ) {
            margin-top: 2em;
        }
            
        @include mq( $from: lg ) {
            padding: 1em 0;
            display: flex;
            flex-direction: column;
        }

        svg {
            @extend %absolute;
            

            @include mq( $until: md ) {
                display: none;
            }
                
            @include mq( $from: md, $until: lg ) {
                @include center_contents;
                @include size(12vw);
                background-color: lighten(c(txt_body), $LIGHTEN_FACTOR*1.1);
                right: 0;
                padding: 1.2em;
                border-radius: 50%;
            }
                
            @include mq( $from: lg ) {
                left: 100%;
                transform: translateX(-50%);
                top: 0;
                width: 4em;
            }
        }
    }

    &__type {
        @extend %upper;
        @extend %titles_sb;
        @extend %txt_body;

        @include mq( $until: md ) {
            
        }
            
        @include mq( $from: md, $until: lg ) {
            
        }
            
        @include mq( $from: lg ) {
            font-size: 1.2em;
        }
    }

    &__title {
        @extend %titles_eb;
        line-height: 1.1;
        margin: 0.15em 0 0.35em;

        @include mq( $until: md ) {
            font-size: 7vw;
            max-width: 11em;
        }
        @include mq( $from: md, $until: lg ) {
            font-size: 6vw;
            max-width: 11em;
        }
            
        @include mq( $from: lg ) {
            font-size: 2.6em;
            max-width: 10em;
        }
    }

    &__subtitle {
        @extend %txt_body;
        @extend %titles_m;
        

        @include mq( $until: md ) {
            background-color: lighten(c(txt_body), $LIGHTEN_FACTOR*1.1);
            margin: 1.5em -1.3rem 1.5em;
            padding: 1em 1.5rem;
            font-size: 1.1em;
        }
            
        @include mq( $from: md, $until: lg ) {
            
        }
            
        @include mq( $from: md ) {
            font-size: 1.3em;
        }
    }

    &__desc {
        @extend %txt_body;
        @extend %body_l;
        margin: 1em 0 1.5em;

        strong {
            @extend %body_eb;
            line-height: 2;
        }
    }

    // ================================================================
    // BTNS
    // ================================================================

    &__btns {
        

        @include mq( $until: md ) {
            background-color: lighten(c(txt_body), $LIGHTEN_FACTOR*1.1);
            padding: 1.5rem;
            margin: 0 -1.3rem;
        }

        @include mq( $until: lg ) {
            text-align: center;
            padding-top: 1.2em;

            [download] {
                display: none;
            }

        }
            
        @include mq( $from: md ) {
            border-top: 1px solid lighten(c(txt_body), $LIGHTEN_FACTOR);
        }
            
        @include mq( $from: lg ) {
            margin-top: auto;
            padding-top: 1em;
            
        }

        
    }

    // ================================================================
    // CLOSE
    // ================================================================

    &__close {
        @extend %z_gohan;
        position: fixed;
        right: r($BASE_FONT_SIZE);
        
        @include mq( $until: md ) {
            bottom: r($BASE_FONT_SIZE);
            font-size: 1.5rem;
        }
            
        @include mq( $from: md ) {
            top: r($BASE_FONT_SIZE);
            
        }
            
        @include mq( $from: lg ) {
            font-size: vw(20, $LG);
        }
    }
}