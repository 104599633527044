html {
    overflow-x: hidden;
    font-size: 1em;
}

body {
    @extend %titles_r;
    @extend %txt_body;
    line-height: $BASE_LEADING;
    font-variant-numeric: lining-nums;
    font-feature-settings: "lnum";
}

a {
    @extend %transition;
    text-decoration: none;
    color: inherit;

    &:active{
        @extend %z_goku;
        transform: scale(0.97);
    }

}

strong {
    font-weight: 700;
}


// Para screen readers

.h {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden !important;
}

