.contact {
    @extend %flex;
    @extend %relative;

    @include mq( $until: lg ) {
        flex-flow: column nowrap;
        padding-top: r($MODULE/1.5);
        
    }
        
    

    &.content {
            
        @include mq( $until: lg ) {
            padding-top: r($MODULE/1.5);
            padding-bottom: r($MODULE);
        }

        @include mq( $from: lg ) {
            padding-bottom: r($MODULE);
        }
    }

    &:hover {
        .grecaptcha-badge {
            transform: translateX(-190px);
        }
    }

    .grecaptcha-badge {
        @extend %transition;
        position: fixed;
        bottom: 10%;
        left: 0;
        transform: translateX(-100%);

        &:hover {
            transform: none;
        }
    }

    
    

    &__title {
        @extend %titles_l;
        @extend %brand;
        line-height: 1.1;

        @include after {
            @extend %block;
            @extend %brand_bg;
            @extend %absolute;
            width: r(5);
            right: 0;
            
            @include mq( $until: md ) {
                
            }
                
            @include mq( $from: md, $until: lg ) {
                bottom: r($MODULE/2);
                height: 55%;
            }
                
            @include mq( $from: lg ) {
                top: r($MODULE);
                height: 75%;
            }

        }

        @include mq( $until: lg ) {
            font-size: 7vw;
            max-width: 12em;
        }

        @include mq( $from: lg, $until: xl ) {
            font-size: vw(42, $LG);
        }
            
        @include mq( $from: lg ) {
            width: 12em;
            flex-basis: 12em;
            margin-left: r($MODULE*1.3);
            
            padding-right: 1vw;
            display: flex;
            flex-flow: column nowrap;
            

            span {
                color: c(txt_body);
                margin-top: auto;
                font-size: 0.5em;
                max-width: 15em;
                
            }

            i {
                color: c(brand);
                display: block;
                font-size: 2em;
                margin-bottom: 1em;
            }

            b {
                color: c(brand);
            }
        }

        @include mq( $from: xl ) {
            font-size: r(42);
        }

        span {
            @extend %body_l;
                
            @include mq( $until: lg ) {
                display: none;
            }
        }

        > b {
            @extend %titles_eb;
        }
    }

    &__form {
        

        @include mq( $until: lg ) {
            @include _uberline( before );
            order: 1;
            margin-top: 2.5em;
            
        }

        @include mq( $from: lg ) {
            text-align: right;
            flex-grow: 1;
            max-width: span(7, 16);
        }

        &-inner {
            
            @include mq( $until: lg ) {
                margin-top: 2.5em;

                label {
                    margin-bottom: 1.5em;
                    display: block;
                }
            }
                
            @include mq( $from: md, $until: lg ) {
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;

                label {
                    flex-basis: 100%;
                    &:not(:last-child) {
                        flex-basis: calc(50% - 0.5em);
                        margin-bottom: 1em;
                    }
                }
            }
                
        }

        hr {
            @include mq( $until: lg ) {
                display: none;
            }
                
            @include mq( $from: lg ) {
                background-color: lighten( c(txt_body), $LIGHTEN_FACTOR );
                position: relative;
                width: 200vw;
                border: none;
                height: 1px;
                left: 50%;
                transform: translateX(-50%);
                z-index: -1;
                margin: 1em 0;
            }
            
        }
    }

    &__footer {

        @include mq( $until: md ) {
            button {
                width: 100%;
                margin-top: 2em;
            }

            [for="newsletter"] {
                font-size: vw(18, $SM);
            }
        }

        @include mq( $from: md, $until: lg ) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        @include mq( $from: md ) {

            button {
                margin-top: 0;
            }

        }

        .btn {
            &:not(:disabled) {
                color: c(brand);
            }
        }

        
            
    }

    .breadcrumb {
        @extend %absolute;
        transform: rotateZ(-90deg);
        left: 80vw;
        bottom: r($MODULE*3.2);
        white-space: nowrap;

        @include mq( $until: xl ) {
            display: none;
        }
    }
}