// ================================================================
// ANIMATIONS
// ================================================================

%transition {
    transition: $TRANSITION;
}

%transition_cubic {
    transition: $TRANSITION_CUBIC;
}

%scroll {
    animation-name: scroll;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

%drag_horizontal {
    animation-name: drag_horizontal;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

@keyframes scroll {
    0% {
        will-change: transform;
    }
    10% {
        transform: translateY(-10%);
    }
    20% {
        transform: translateY(10%);
    }
}

@keyframes drag_horizontal {
    0% {
        will-change: transform;
    }
    50% {
        transform: translateX(-20%);
    }
}
