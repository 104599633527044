.styled-select {
    @extend %relative;
    @extend %pointer;
    max-width: 100%;
    

    &:not(.Active) {

        .styled-select__items {
            display: none;
        }
        
        .styled-select__placeholder {
            @include before('\f078'); 
        }

        .styled-select__overlay {
            display: none;
        }
    }
    

    &__overlay {
        @include size(100vw 100vh);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 0;
    }

    &__placeholder {
        @extend %full_width;
        @include global_input;
        overflow: hidden;
        position: inherit;
        user-select: none;

        @include after {
            @extend %block;
            @extend %absolute;
            @include size(4em 100%);
            background: linear-gradient(
                            to left,
                            rgba(lighten( c(txt_body), $LIGHTEN_FACTOR*1.12 ), 1) 55%,
                            rgba(lighten( c(txt_body), $LIGHTEN_FACTOR*1.12 ), 0.5) 80%,
                            rgba(lighten( c(txt_body), $LIGHTEN_FACTOR*1.12 ), 0) 
                        );
            right: 0;
            top: 0;
        }

        @include before('\f077') {
            @extend %z_gotten;
            @extend %block;
            @extend %absolute;
            @extend %brand;
            @include size(2.5em 100%);
            @include center_contents;
            font-family: FontAwesome;
            right: 0;   
            top: 0;    
        }

        > span {
           @extend %absolute;
           white-space: nowrap; 
           text-transform: none;
           left: 0;
           padding: inherit;
           top: 0;
            
        }
    }

    

    &__items {
        @extend %absolute;
        @extend %full_width;
        @extend %z_gohan;
        top: 3.1em;
        left: 0;
        font-size: 0.9em;
        max-height: 15em;
        overflow-y: scroll;
        user-select: none;
            
        @include mq( $from: lg ) {
            top: 4em;
        }
    }

    &__item {
        @include global_input;
        text-align: left;
        margin: 0;
        border-color: c(txt_body);
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-transform: none;

        &:hover {
            @extend %brand;
            background-color: lighten( c(txt_body), $LIGHTEN_FACTOR );
        }

        &.Active {
            @extend %brand;
            @include after ('\f058') {
                font-family: FontAwesome
            }
        }

    }

}