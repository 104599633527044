.data {
    @extend %flex;
    @extend %relative;
    
    overflow: visible;

    @include mq( $until: lg ) {
        flex-flow: column nowrap;
        align-items: center;

        &__info {
            order: 1;
        }
        
    }

    @include mq( $from: lg ) {
        align-items: flex-end;
        border-bottom: 2px solid currentColor;
    }
    
    
    &__info {
        @extend %safe_l;
        @extend %body_l;
        @extend %relative;
        @extend %white_bg;
        @extend %z_gotten;
        
        @include after {
            @extend %block;
            @extend %brand_bg;
            @extend %absolute;
            height: calc( 100% - #{ r($MODULE*3) } );
            top: r($MODULE*1.5);
            right: 0;
            width: r(5);
        }

        @include mq( $until: md ) {
            padding: 5vw;
        }

        @include mq( $from: md, $until: lg ) {
            padding: 2em 3em;
        }

        @include mq( $until: lg ) {
            box-shadow: 0 0 0.3em rgba(c(black), 0.2);
            width: calc(100% - 10vw);
            margin-top: -5em;
            display: flex;
            flex-flow: row wrap;
            width: calc(100vw - 10vw);
            flex-grow: 0;
            flex-shrink: 0;
        }

        @include mq( $from: lg ) {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 40%;
            padding: r($MODULE*1.5) 0;
        }
       

        &-item {
            @extend %flex;
            flex-flow: row wrap;
            align-items: center;
            

            @include mq( $until: md ) {
                font-size: vw(13, $SM);
                
                &:first-child {
                    font-size: vw(30, $SM)
                }
            }
                
            @include mq( $until: lg ) {
                font-size: vw(15, $MD);
                
                &:not(:first-child) {
                    flex-basis: auto;
                    margin-bottom: 1em;
                    flex-grow: 1;
                    flex-shrink: 1;
                }

                &:nth-child(3),
                &:nth-child(5) {
                    flex-basis: 60%;
                }

                &:first-child {
                    display: block;
                    border-bottom: 1px solid lighten(c(txt_body), $LIGHTEN_FACTOR);
                    text-align: center;
                    font-size: 2em;
                    color: c(brand);
                    font-family: unquote(map-get($FONTS, FONT_TITLES));
                    padding: 0.7em 0 0.6em;
                    position: absolute;
                    left: 0;
                    bottom: 100%;
                    background-color: inherit;
                    width: 100%;

                    @include before( "Estamos en" );
                    @include after( " Villa Domínico" ) {
                        font-weight: 700;
                    }

                    p, span {
                        display: none;
                    }

                    i {
                        display: none;
                    }
                }
                
            }

            @include mq( $from: lg, $until: xl ) {
                font-size: vw(15, $LG);
            }

                
            @include mq( $from: lg ) {
                

                &:first-child,
                &:nth-child(3) {
    
                    @include after {
                        display: block;
                        width: 100%;
                        background-color: lighten(c(txt_body), $LIGHTEN_FACTOR);
                        flex-grow: 1;
                        height: 1px;
                        margin: 3em 0;
                    }
                }
    
                &:nth-child(2),
                &:nth-child(4) {
                    margin-bottom: 1em;
                }
            }

            @include mq( $from: xl ) {
                font-size: r(13);
            }
            

            p {
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: 0;
                padding-right: r($MODULE/2);

                > span {
                    @extend %body_eb;
                    @extend %block;
                    font-size: 1.4em;

                    > span {
                        @extend %body_l;
                    }

                    > small {
                        @extend %block;
                        @extend %body_l;
                        font-size: r(12);
                    }
                }
            }

            i {
                @extend %white;
                @extend %brand_bg;
                @include center_contents;
                @include size(1.8em);
                flex-basis: 1.8em;
                flex-grow: 0;
                flex-shrink: 0;
                margin-right: 1em;
                font-size: 2em;
            }
        }

        
    }

    &__map {
            
        @include mq( $until: lg ) {
            width: 100%;
            height: 40rem;
            flex-basis: 100%;
            flex-shrink: 0;
            flex-grow: 0;
            border-bottom: r(6) solid c(brand);
        }
            
        @include mq( $from: lg ) {
            min-height: r(500);
            height: 100vh;
            max-height: r(800);
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 60vw;
            width: 60vw;
        }
    }

    .breadcrumb {
        @extend %absolute;
        transform: rotateZ(-90deg);
        right: 80vw;
        bottom: r($MODULE*3.8);
        white-space: nowrap;

        @include mq( $until: xl ) {
            display: none;
        }
    }

}