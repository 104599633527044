.Productos {
    @extend %relative;
    @extend %safe_l;

    @include before {
        @extend %z_gotten;
        @extend %absolute;
        @extend %block;
        pointer-events: none;
        min-width: 5vw;
        background: linear-gradient(to left,
                                    rgba(c(white), 1) 55%,
                                    rgba(c(white), 0.5) 80%,
                                    rgba(c(white), 0) 
                                    );
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        @include mq( $until: md ) {
            
        }
            
        @include mq( $from: md, $until: lg ) {
            width: calc( ( 100vw - #{ r($SAFE_MD) } ) / 2 + 10vw );
            height: calc( 100% - #{r(370)});
        }
            
        @include mq( $from: lg ) {
            width: calc( ( 100vw - #{ r($SAFE_LG) } ) / 2 + 10vw );
            height: calc( 100% - #{r(300)} );
        }
    }

    .content__foot {
        @include mq( $until: md ) {
            margin-top: r($BASE_FONT_SIZE);
        }
            
        @include mq( $from: md, $until: lg ) {
            margin-top: r($MODULE/2);
        }
            
        @include mq( $from: lg ) {
            margin-top: r($MODULE/1.5);
        }
        
    }

    .content__body {
            
        @include mq( $from: lg ) {
            display: flex;
        }

        > article {
            
            @include mq( $from: lg ) {
                align-self: flex-end;
                padding-right: span(1, 16);
                width: r(400);
                flex-basis: r(400);
                flex-shrink: 0;
            }

            h2 {
                @extend %titles_eb;
                line-height: 1;
                margin-bottom: 0.4em;

                @include mq( $until: md ) {
                    font-size: 3em;
                    font-size: 7vmax;

                    &::first-letter {
                        text-transform: uppercase;
                    }
                }
                    
                @include mq( $from: md, $until: lg ) {
                    font-size: 4em;
                    font-size: 6vmax;
                }

                @include mq( $until: lg ) {
                    @include _uberline( after ) {
                        margin-top: 0.7em;
                    }
                }
                    
                @include mq( $from: lg, $until: xl ) {
                    font-size: vw(54, $LG);
                }

                @include mq( $from: xl ) {
                    font-size: r(54);
                }

                @include before {
                    @extend %full;
                    @extend %absolute;
                    @extend %z_neg;
                    opacity: 0.02;
                    filter: invert(1);
                    pointer-events: none;
                    left: 0;
                    top: 0;
                    background-image: url("../img/loader.svg");
                    background-repeat: no-repeat;
                    

                    @include mq( $until: md ) {
                        background-size: auto 40%;
                        background-position: -10rem center;
                    }
                        
                    @include mq( $from: md ) {
                        background-size: auto 70%;
                        background-position: -20rem center;
                    }
                }
        
                span {
                    @extend %titles_l;

                    @include mq( $until: md ) {
                        display: none;
                    }
                        
                    @include mq( $from: lg ) {
                        display: block;
                    }
                }
            }

            p {
                @extend %body_l;

                @include mq( $until: lg ) {
                    display: none;
                }
                    
                @include mq( $from: lg, $until: xl ) {
                    font-size: vw(17, $LG);
                }

                @include mq( $from: xl ) {
                    font-size: r(14);
                }

            }

        }

        [drag-horizontal] {
            @extend %z_gotten;
            @extend %white_bg;
            @include center_contents;
            @include size(1.5em 3em);
            left: 100%;
            top: 50%;
            position: absolute;
            transform: translate(-100%, -50%);
            box-shadow: 0 0 0.3em rgba(c(black), 0.2);
            border-top-left-radius: 500em;
            border-bottom-left-radius: 500em;

            @include mq( $until: lg ) {
                font-size: 2em;
            }
                
            @include mq( $from: lg ) {
                font-size: 2.5em;
            }
        }
    }

    

    .dragscroll {

        @include mq( $from: lg ) {
            overflow-x: scroll;
            overflow-y: hidden;
            padding-top: 1em;
            padding-bottom: 3em;
    
            &:hover {
                cursor: grab;
            }
    
            &:active {
                cursor: grabbing;
            }
        }
        
    }

    .products {
        @extend %full_width;
        
        @include mq( $from: md, $until: lg ) {
            padding-top: r($MODULE/2);
        }
    
        &__row {
            @extend %flex;
            padding-right: 60em;

            @include mq( $until: md ) {
                padding: r($MODULE/3) 0 r($MODULE/4);
            }
                
            @include mq( $from: md, $until: lg ) {
                padding: r($MODULE/2) 0;
            }
                
            @include mq( $until: lg ) {
                width: 100%;
                overflow-x: scroll;
                overflow-y: hidden;


                @include cloner {
                    position: relative;
                    border-bottom: 1px solid lighten(c(txt_body), $LIGHTEN_FACTOR);
                }

            }

            @include mq( $from: lg ) {

                @include cloner {
                    margin-bottom: r($MODULE/2);
                }
                
            }
            

        }
    

    }

}

