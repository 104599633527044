// ================================================================
// LAYOUT
// ================================================================

// Sizes
// ----------------------------------------------------------------

%safe_globals {
    margin-left: auto;
    margin-right: auto;
    width: 95vw;
}

%safe_sm {
    @extend %safe_globals;

    @include mq( $until: md ) {
        max-width: r($SAFE_SM);
    }
}

%safe_md{
    @extend %safe_globals;

    @include mq( $from: md, $until: lg ) {
        max-width: r($SAFE_MD);
    }
}

%safe_lg {
    @extend %safe_globals;

    @include mq( $from: lg ) {
        max-width: unquote( $SAFE_LG + "px" );
    }
}

%safe {
    @extend %safe_sm;
    @extend %safe_md;
    @extend %safe_lg;
}

%safe_l {

    @include mq( $until: xl ) {
        padding-left: 7vw;
    }

    @include mq( $from: xl ) {
        padding-left: website_margins( $SAFE_LG );
    }
}

%safe_r {
    @include mq( $until: xl ) {
        padding-right: 7vw;
    }

    @include mq( $from: xl ) {
        padding-right: website_margins( $SAFE_LG );
    }

}

%safe_pad {
    @extend %safe_l;
    @extend %safe_r;
}

%extend_bg {
    @include extend_bg;
}

%m_avoid_safe {
    @include mq( $until: lg ) {
        width: 100vw;
    }
    @include mq( $until: md ) {
        margin-left: calc( ( 100vw - #{ r($SAFE_SM) } ) / (-2) );
    }

    @include mq( $from: md, $until: lg ) {
        margin-left: calc( ( 100vw - #{ r($SAFE_MD) } ) / (-2) );
    }
}

%fluid {
    max-width: 100%;
}

%full_width {
    width: 100%;
}

%full_height {
    height: 100%;
}

%full {
    @extend %full_width;
    @extend %full_height;

}

// Display
// ----------------------------------------------------------------

%block {
    display: block;
}

%i_block {
    display: inline-block;
}

%table {
    display: table;
}

%table_cell {
    display: table-cell;
}

%t_middle {
    @extend %table_cell;
    vertical-align: middle;
}

%flex {
    display: flex;
}

// Position
// ----------------------------------------------------------------

%absolute {
    position: absolute;
}

%relative {
    position: relative;
}

// z-index
// ----------------------------------------------------------------

%z {
    z-index: 100;
}

%z_gotten {
    z-index: 200;
}

%z_gohan {
    z-index: 300;
}

%z_goku {
    z-index: 500;
}

%z_neg {
    z-index: -1;
}

//=========================================================
// GRID
//=========================================================

$grid_name: map-get( $GRID, name );
$gutter_sm: map-get( $GRID, gutter_sm );
$gutter_md: map-get( $GRID, gutter_md );
$gutter_lg: map-get($GRID, gutter_lg);

$output: "%";
@if map-get( $GRID, explicit ) == true {
    $output: ".";
}


[grid] {
   display: flex;
}

[columns] {
    flex-grow: 0;
    flex-shrink: 0;
    @include cloner {
        @include mq( $until: md ) {
            margin-right: $gutter_md;
        }
            
        @include mq( $from: md, $until: lg ) {
            margin-right: $gutter_md;
        }
            
        @include mq( $from: lg ) {
            margin-right: $gutter_lg;
        }
    }
}

@for $i from 1 to (map-get( $GRID, columns )+1) {

    $span: map-get( $GRID, columns ) / $i;

    [columns*='"sm": #{ $i }'] {
        @include mq( $until: md ) {
            width: span( $i, 4 );
            flex-basis: span( $i, 4 );
        }
    }

    [columns*='"md": #{ $i }'] {
        @include mq( $from: md, $until: lg ) {
            width: span( $i, 8 );
            flex-basis: span( $i, 8 );
        }
    }

    [columns*='lg'][columns~="#{ $i }"] {

        @include mq( $from: lg ) {
            width: span( $i, 16 );
            flex-basis: span( $i, 16 );
        }
    }
}
