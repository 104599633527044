// ================================================================
// OVERRIDE DEFAULT STYLES
// ================================================================

button {
    @extend %pointer;
    @extend %z_gotten;
    @extend %relative;
    border: none;
    outline: none;
    background-color: transparent;
    color: inherit;
}


// ================================================================
// LOCAL MIXINS
// ================================================================

@mixin _mobile_styles {
    border-radius: 10em;
    background-color: currentColor;
    overflow: hidden;
    padding-left: 0.5em;
    padding-right: 2em;
    height: 3em;

    &:hover {
        span {
            opacity: 0.6;
        }
    }

    i {
        color: c(white);
    }

    span {
        color: c(white);

    }
}


// ================================================================
// LET THE FUN BEGIN
// ================================================================

.btn {
    @extend %pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &:hover {
        
        i::before {
            opacity: 0.6;
        }
        
    }

    i {
        @extend %transition;
        @include center_contents;
        @include size(2em);
        display: inline-flex;
        font-size: 1.2em;
        border-radius: 50em;

        &::before {
            @extend %transition;
        }

        &.fa-chevron-right::before {
            transform: translateX(10%);
        }

        &.fa-chevron-left::before {
            transform: translateX(-10%);
        }
    }

    // ================================================================
    // STANDARD
    // ============ ====================================================
    
    &.Basic {
        @extend %upper;
        @extend %titles_b;
        @extend %transition;
        @extend %pointer;

        @include cloner {
            
            @include mq( $from: md, $until: lg ) {
                margin-right: 1em;
            }
                
            @include mq( $from: lg ) {
                margin-right: 1.5em;
            }
        }
        

        @include mq( $until: lg ) {
            @include _mobile_styles;
        }
            
        @include mq( $from: md, $until: lg ) {
            
        }

        @include mq( $until: lg ) {
            background-color: currentColor;
            border-radius: 50em;
            font-size: m(12);
        }
            
        @include mq( $from: lg ) {
            padding: 0;
            font-size: m(14);
        }


        span {
            @extend %transition;
            
            @include mq( $from: lg ) {
                color: c(txt_body);
            }
        }


        i {
            margin-right: 0.4em;
                
            @include mq( $from: lg ) {
                background-color: c(white);
                box-shadow: 0 0 0.3em rgba(c(black), 0.2);
            }
            

        }

        &:hover {

                
            @include mq( $from: lg ) {
                span {
                    color: currentColor;
                }
            }
            
            
            
        }


    }


    // ================================================================
    // ONLY ICON
    // ================================================================

    &.Icon {
        i {
            background-color: c(white);
            box-shadow: 0 0 0.3em rgba(c(black), 0.2);
        }
    }

    // ================================================================
    // MOBILE STYLES AS DEFAULT
    // ================================================================

    &.Alt {
        @include _mobile_styles;
    }
    
}