.about {
    @extend %brand_bg;
    @extend %white;
    @extend %relative;

    &::before,
    &::after {
        @extend %block;
        @extend %absolute;
        @extend %full_height;
        opacity: 0.2;
        border-width: 1px;
        border-color: c(white);
        border-top-style: solid;
        top: r($MODULE);
        pointer-events: none;
    }

    @include after {
        border-left-style: solid;
        width: 30vw;
        right: 0;
    }

    @include before {
        border-right-style: solid;
        width: 10vw;
        left: 0;
    }


    @include mq( $until: md ) {
        padding-bottom: r($BASE_FONT_SIZE);
        padding-top: r($MODULE/1.5);
    }
        
    @include mq( $from: md, $until: lg ) {
        padding-bottom: r($BASE_FONT_SIZE);
        padding-top: r($MODULE);
    }

    @include mq( $from: lg, $until: xl ) {
        padding-bottom: vw($MODULE*2, $LG);
        padding-top: vw($MODULE*2, $LG);
    }
        
    @include mq( $from: xl ) {
        padding-bottom: r($MODULE*1.5);
        padding-top: r($MODULE*1.5);
    }

    &__title {
        @extend %titles_t;
        @extend %relative;

        @include mq( $until: md ) {
            font-size: 3em;
            font-size: 7vmax;
            display: inline-block;
        }
            
        @include mq( $from: md, $until: lg ) {
            font-size: 4em;
            font-size: 6vmax;
        }

        @include mq( $from: md ) {
            display: block;
        }
            
        @include mq( $from: lg ) {
            font-size: 4em;
        }

        @include after {
            @extend %absolute;
            @extend %block;
            background-image: url("../img/loader.svg");
            background-size: contain;
            background-repeat: no-repeat;
            top: 50%;

            @include mq( $until: md ) {
                @include size(0.7em);
                left: 100%;
                transform: translate(20%, -130%);
            }

            @include mq( $from: md ) {
                transform: translateY(-50%);
            }
                
            @include mq( $from: md, $until: lg ) {
                @include size(1.5em);
                right: 0;
            }
                
            @include mq( $from: lg ) {
                @include size(0.8em);
                right: r($MODULE);
            }
        }

        strong {
            @extend %titles_eb;
        }
    }

    &__main {
        @extend %body_l;
        

        @include mq( $until: md ) {
            font-size: 1.1em;
            margin: 1.5em 0 2em; 
        }
            
        @include mq( $from: md, $until: lg ) {
            font-size: 1.5em;
            width: 25em;
            max-width: 100%;
        }
            
        @include mq( $from: md ) {
            margin: 1.5em 0 3em; 
        }

        @include mq( $from: lg ) {
            font-size: 1.5em;
            width: span(8, 16);
        }
    }

    &__mkt {
        @extend %flex;

        @include mq( $until: md ) {
            overflow: scroll;
        }
            
        @include mq( $from: md, $until: lg ) {
            justify-content: space-between;
            flex-wrap: wrap;
        }
            
        @include mq( $from: md ) {
            margin-bottom: 5em;
        }

        &-item {
            
            @include mq( $until: md ) {
                background: c(white);
                color: c(brand);
                padding: 2em 1.5em;
                flex-basis: calc(100% - 2em);
                flex-shrink: 0;
                max-width: 23em;

                @include cloner {
                    margin-right: 1em;
                }
            }
                
            @include mq( $from: md, $until: lg ) {
                flex-basis: 20rem;
                margin-bottom: 2em;
                margin-right: 2em;
                flex-grow: 1;
            }
                
            @include mq( $from: lg ) {
                width: span(5, 16);
                flex-basis: span(5, 16);
                padding-right: span(1, 16);
            }
        }
        
        &-title {
            @extend %titles_b;
            margin-bottom: 1em;

            @include mq( $until: md ) {
                font-size: 1.2em;
            }
                
            @include mq( $from: md, $until: lg ) {
                font-size: 1.6em;
            }
                
            @include mq( $from: lg ) {
                font-size: 1.5em;
            }
        }

        &-desc {
            @extend %body_l;
            @include mq( $until: md ) {
                font-size: 0.8em;
            }
                
            @include mq( $from: md, $until: lg ) {
                font-size: 1.2em;
            }
                
            @include mq( $from: lg ) {
                font-size: 0.9em;
            }
        }
        
    }

    [drag-horizontal] {
        @extend %z_gotten;
        @extend %white_bg;
        @extend %brand;
        @include center_contents;
        @include size(2em);
        box-shadow: 0 0 0.3em rgba(c(black), 0.2);
        border-radius: 50%;
        font-size: 2em;
        margin: 0.5em auto 0;

        @include mq( $from: md ) {
            display: none;
        }
            
    }

    .breadcrumb {
        @extend %absolute;
        transform: rotateZ(-90deg);
        left: 80vw;
        bottom: r($MODULE*4);
        white-space: nowrap;
    }

    .btn {

        @include mq( $until: lg ) {
            display: none;
        }
            
        @include mq( $from: lg ) {
            i::before {
                color: c(brand);
            }
    
            span {
                color: c(white);
            }
        }
        
    }

}
