.iwParent {
    width: r(300) !important;
    height: auto !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    filter: drop-shadow(0 0 5px rgba(0,0,0,0.3));
}

.gm-style-iw {
    @extend %white_bg;
    border-bottom: 0.2em solid c(brand);
    width: 100% !important;
    top: 0 !important;
    left: 0 !important;
    overflow: visible !important;
    

    @include before {
        @extend %block;
        @extend %full_width;
        height: r(90);
        @include bg_img("logo.png", $img_size: r(230) auto);
    }

    @include after {
        @extend %absolute;
        @include triangle(2em 1em, c(brand), down);
        top: calc(100% + 0.2em);
        left: 50%;
        transform: translateX(-50%);
    }

    > div {
        display: none !important;
    }
}

.iwClose {
    display: none;
}

.iwSibling {
    div {
        display: none;
    }
}