

.product {

    $_: &;

    @extend %flex;
    @extend %pointer;
    @extend %relative;
    flex-direction: column;
    justify-content: space-between;
    font-size: r(18);
    line-height: 1.2;
    user-select: none;
    
    @include mq( $until: md ) {
        
    }
        
    @include mq( $until: lg ) {
        @include cloner {
            margin-right: 2em;
        }
        &:last-child {
            padding-right: 10em;
        }
    }
        
    @include mq( $from: lg ) {
        border-top: 0.2em solid currentColor;
        padding-top: 0.8em;
        @include cloner {
            margin-right: r($BASE_FONT_SIZE);
        }
    }

    &__title {
        @extend %titles_eb;

        @include mq( $until: md ) {
            font-size: 1.2em;
            width: 10em;
        }

        @include mq( $from: md, $until: lg ) {
            font-size: 1.4em;
            width: r(280);
        }

        @include mq( $from: lg ) {
            font-size: 1.15em;
            width: r(210);
        }
    }

    &__model {
        @extend %upper;
        @extend %body_b;
        @extend %flex;
        margin-top: 1em;
        letter-spacing: -0.02em;
        align-items: center;
        
        @include mq( $until: md ) {
            font-size: 0.7em;
        }
            
        @include mq( $from: md, $until: lg ) {
            
        }
            
        @include mq( $from: md ) {
            font-size: 0.8em;
        }

        @include after( '\f067' ) {
            @extend %block;
            @extend %white_bg;
            font-family: FontAwesome;
            margin-left: auto;
            line-height: 0;

            @include mq( $until: md ) {
                
            }
                
            @include mq( $until: lg ) {
                @include center_contents;
                @include size(2em);
                border-radius: 50%;
                font-size: 1.5em;
                box-shadow: 0 0 0.3em rgba(c(black), 0.2);
            }
                
            @include mq( $from: lg ) {
                font-size: 1.3em;
            }
        }

        dt {
            @extend %txt_body;
            @include after {
                @extend %i_block;
                width: 2em;
                border-bottom: 1px solid currentColor;
                margin: 0 0.3em;
            }
        }

        dd {
            
            
        }
    }

    &__actions {
        @extend %absolute;
        @extend %transition;
        @include center_contents;
        background-color: currentColor;
        height: calc(100% + #{ r($BASE_FONT_SIZE*1.5)});
        width: calc(100% + #{ r($BASE_FONT_SIZE*0.5)});
        top: 50%;
        left: 50%;
        opacity: 0;
        transform: translate(-50%, -50%);

        @include mq( $until: lg ) {
            display: none;
        }
            
        svg {
            fill: c(white);
            height: 60%;
        }

        #{ $_ }:hover & {
            opacity: 1;
        }
    }


    &__btns {
        @include mq( $until: md ) {
            
        }
            
        @include mq( $from: md, $until: lg ) {
            
        }
            
        @include mq( $from: lg ) {
            position: absolute;
            top: 100%;
            right: r($BASE_FONT_SIZE/2);
            transform: translateY(-60%);
        }
    }

    .btn {
        margin: 0;
        padding: 0;
        font-size: 0.8em;
    }

}
