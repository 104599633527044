@mixin _uberline( $location ) {

    $space: if( $location == after, margin-top, margin-bottom );

    &::#{$location} {
        background-color: lighten(c(txt_body), $LIGHTEN_FACTOR);
        content: "";
        display: block;
        position: relative;
        width: 100vw;
        transform: translateX(-50%);
        left: 50%;
        height: 1px;
        #{ $space }: r($BASE_FONT_SIZE*1.5);
        @content;
    }

}

.content {

    $_: &;

    @extend %safe_pad;
    

    @include mq( $until: md ) {
        padding-top: r($MODULE/1.2);
        padding-bottom: r($MODULE/2);
    }
        
    @include mq( $from: md, $until: lg ) {
        padding-top: r($MODULE);
        padding-bottom: r($MODULE/2);
    }

    @include mq( $from: lg, $until: xl ) {
        padding-top: vw( ($MODULE*1.5), $LG );
        padding-bottom: vw( ($MODULE*1.2), $LG );
    }
        
    @include mq( $from: xl ) {
        padding-top: r($MODULE);
        padding-bottom: r($MODULE/2);
    }

    &__head {
        @include _uberline( after );
        margin-bottom: r($MODULE);
            
        @include mq( $until: lg ) {
            display: none;
        }
    }

    &__foot {
        @include _uberline( before ) {

            @include mq( $until: lg ) {
                margin-bottom: 2em;
            }
                
            @include mq( $from: lg ) {
                
            }
        }

        @include mq( $until: md ) {
            font-size: 1.1em;
        }

        @include mq( $from: md, $until: lg ) {
            font-size: 1.2em;
        }
           
            
        @include mq( $until: lg ) {
            text-align: center;
            font-size: 1.2em;
        }
            
        @include mq( $from: lg ) {
            text-align: right;
        }

        .btn {
            @extend %brand;

            @include mq( $until: md ) {

                @include mq( $and: "(orientation: landscape)") {

                    @include cloner {
                        margin-right: 1em;
                    }
                    
                }

                @include mq( $and: "(orientation: portrait)") {
                    width: 100%;

                    @include cloner {
                        margin-bottom: 1em;
                    }

                }

            }
                
            &:last-child {
                @include mq( $until: lg ) {
                    background-color: c(white);
                    border: r(2) solid c(brand);
                    color: c(brand);

                    span, i {
                        color: currentColor;
                    }
                }
            }
                
            
        }
    }

  
}
