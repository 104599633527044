$scrollbar_radius: 1rem;
$scrollbar_thickness: r(10);

// ================================================================
// WEBKIT
// ================================================================

@include mq( $until: md ) {
    
}
    
@include mq( $from: md, $until: lg ) {
    
}
    
@include mq( $from: lg ) {
    ::-webkit-scrollbar {
        width: $scrollbar_thickness;
        height: $scrollbar_thickness;
    }
     
    ::-webkit-scrollbar-track {
        background-color: darken( c(white), $DARKEN_FACTOR );
    }
     
    ::-webkit-scrollbar-thumb {
        border-radius: $scrollbar_radius;
        background-color: darken(c(white), $DARKEN_FACTOR*2.5);
    }
    ::-webkit-scrollbar-thumb:window-inactive {
        background-color: darken(c(white), $DARKEN_FACTOR*2.5);
    }
}


// ================================================================
// FIREFOX
// ================================================================

