// ================================================================
// BACKGROUND IMAGE
// ================================================================

@mixin bg_img( $img_name,
               $img_url: "../img/",
               $img_size: cover,
               $img_position_x: center,
               $img_position_y: center,
               $img_repeat: no-repeat,
               $bg_color: null )
{
    background-image: url( $img_url + $img_name );
    background-position: $img_position_x $img_position_y;
    background-repeat: $img_repeat;
    background-size: $img_size;
    background-color: $bg_color;
}


// ================================================================
// BORDER
// ================================================================

@mixin border( $border_color,
               $border_top: 0,
               $border_right: 0,
               $border_bottom: 0,
               $border_left: 0,
               $border_style: solid )
{
    border-color: $border_color;
    border-style: $border_style;
    border-top-width: $border_top;
    border-right-width: $border_right;
    border-bottom-width: $border_bottom;
    border-left-width: $border_left;
}


// ================================================================
// CENTER CONTENTS
// ================================================================

@mixin center_contents( $type: flex, // flex - table - absolute
                        $children: "> *" // .clase - #id - etc.
) {
    // ============================================================
    // Flex
    // ============================================================
    @if $type == flex {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    // ============================================================
    // Table
    // ============================================================
    @else if $type == table {
        display: table;
        width: 100%;

        #{ $children } {
            display: table-cell;
            vertical-align: middle;
        }
    }
    // ============================================================
    // Absolute
    // ============================================================
    @else if $type == absolute {
        //position: relative;

        #{ $children } {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate( -50%, -50% );
        }
    }
}


// ================================================================
// GRID
// ================================================================

@mixin grid( $item_count,
             $item_gutter,
             $children: "*",
             $type: float,
             $margin_bottom: true,
             $container_neg_margin: false )
{
    $unitless_gutter: str-replace( #{ $item_gutter }, 'rem', '' );
    $unitless_gutter: number($unitless_gutter);
    $a: 100 / $item_count * 1%;
    $b: ( ( $item_count - 1 ) / $item_count * $unitless_gutter ) * 1rem;
    $children_width: calc(#{ $a } - #{ $b } /*- 0.4px*/);

    & {
        @if $container_neg_margin == true {
            margin-bottom: -$item_gutter;
        }

        // ============================================================
        // Flex
        // ============================================================

        @if $type == flex {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: flex-start;
            align-content: center;

            &#{ "> " + $children } {
                flex-basis: $children_width;
                flex-shrink: 0;
                flex-grow: 0;
            }

        }

        // ============================================================
        // Not flex
        // ============================================================

        @else if $type != flex {
            &::after {
                content: "";
                display: table;
                clear: both;
            }
            &#{ "> " + $children } {
                float: left;
                width: $children_width;
            }
        }

        // ============================================================
        // Agnostic variations for children
        // ============================================================

        &#{ "> " + $children } {
            &:not(:nth-of-type(#{$item_count}n)) {
                margin-right: $item_gutter;
            }
            @if $margin_bottom == true {
                margin-bottom: $item_gutter;
            }

        }


    }
}


// ================================================================
// CLONER
// ================================================================

@mixin cloner() {
    &:not(:last-child) {
        @content;
    }
}


// ================================================================
// PSEUDO ELEMENTS
// ================================================================

@mixin after( $content: "" ) {
    &::after {
        content: $content;
        @content;
    }
}

@mixin before( $content: "" ) {
    &::before {
        content: $content;
        @content;
    }
}


// ================================================================
// EXTEND BG
// ================================================================

@mixin extend_bg {
    overflow: visible;
    position: relative;
    @include before {
        position: absolute;
        z-index: -1;
        height: calc( 100% + 1px );
        left: 50%;
        top: 50%;
        transform: translate( -50%, -50% );
        background-color: inherit;
        width: 105vw;
    }
}
