.footer {
    @extend %safe_pad;

    @include mq( $until: lg ) {
        font-size: 0.8em;
    }
        
    @include mq( $from: lg ) {
        display: flex;
        font-size: r(11);
    }

    // ================================================================
    // COPYRIGHT
    // ================================================================

    &__copyright-item {
        @extend %txt_body_bg_lt;
        @extend %body_b;
        @extend %upper;
        @extend %flex;
        align-items: center;
        padding: 0.8em 1.2em;
        @include cloner {
            margin-right: 1em;
        }
        
        @include mq( $until: lg ) {
            
            &:last-child {
                position: absolute;
                left: -999vw;
            }
            
        }
            
        @include mq( $from: md, $until: lg ) {
            
        }
            
        @include mq( $from: lg ) {
        }
    }

    svg {
        @include size(2em);
        margin-right: 1em;
    }

    path {
        fill: c(brand) !important;
    }
}
