.notification {
    @extend %flex;
    @extend %brand;
    z-index: 1002;
    position: fixed;
    padding: 1.5em 2em 2em;
    align-items: center;
    bottom: 5%;
    animation-name: notif;
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    box-shadow: 0 0 2em rgba( c(black), 0.3 );
    background-color: currentColor;

    @include after {
        @extend %block;
        @extend %absolute;
        @extend %black_bg;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 2em * 2);
        height: 0.8em;
        bottom: 0;
        opacity: 0.1;
    }

    @include mq( $until: md ) {
        font-size: 0.7em;
        text-align: center;
        width: 90%;
        max-width: 25em;
        transform: translateX(-50%);
        left: calc( 50% - #{ 25em/2 } );
    }
        
    @include mq( $from: md, $until: lg ) {
        
    }
        
    @include mq( $from: md ) {
        width: r(500);
        left: calc( 50% - #{ r(500)/2 } );
    }

    &__btn {
        @include center_contents;
        @include size(2.2em);
        @extend %absolute;
        @extend %pointer;
        font-size: 1.1em;
        top: 0;
        right: 0;
        padding: 1.2em;
        @extend %white;
    }
    

    > i { 
        @extend %white;
        
            
        @include mq( $until: md ) {
            margin-right: 0.2em;
            font-size: 3.5em;
        }

        @include mq( $from: md ) {
            margin-right: 0.5em;
            font-size: 4.4em;
        }
        
        
    }

    h2 {
        @extend %titles_b;
        font-size: 1.2em;
        @extend %white;

        @include mq( $until: md ) {
            
        }
            
        @include mq( $from: md, $until: lg ) {
            
        }
            
        @include mq( $from: md ) {
            margin-bottom: 0.2em;
        }
    }

    p {
        @extend %body_l;
        opacity: 0.8;
        font-size: 0.9em;
        @extend %white;

        @include mq( $until: md ) {
            display: none;
        }
            
    }

    a[href*="#"] {
        @extend %white;
        @extend %body_l;
        text-decoration: underline;
        font-size: 0.9em;
    }
}

@keyframes notif {
    0% {
        transform: translateY(1em);
        opacity: 0;
    }
    10% {
        transform: translateY(0);
        opacity: 1;
    }

    90% {
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        transform: translateY(1em);
        opacity: 0;
    }
}