// ================================================================
// HEADER
// ================================================================

.header {
    @extend %full_width;
    @extend %safe_pad;
    @extend %white_bg;
    @extend %z_gohan;
    @extend %body_b;
    @extend %upper;
    @extend %transition;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;

    @include mq( $until: md ) {
        font-size: vw(22, $SM);
        flex-flow: column nowrap;
        justify-content: center;
        padding-top: 1.5em;
        padding-left: 0;
        padding-right: 0;
    }

    @include mq( $from: md ) {
        font-size: r(14);
        flex-flow: row nowrap;
        justify-content: space-between;
        border-bottom: 1px solid lighten(c(txt_body), $LIGHTEN_FACTOR);
        
        @include mq( $and: "(orientation: portrait)" ) {
            font-size: vw(18, $MD);
        }
    }

    @include mq( $from: md, $until: lg ) {
        height: r(map-get($HEADER_HEIGHT, MD));
    }

    @include mq( $from: lg ) {
        height: r(map-get($HEADER_HEIGHT, LG));
    }

    &.Mini {
        color: c(brand);
        
        &:not(:hover) {
            background-color: rgba(c(white), 0.8);
        }

        @include mq( $until: md ) {
            font-size: r(12);
            transform: translateY(calc( -100% + #{r(map-get($HEADER_HEIGHT, SM))} ));
        }

        @include mq( $from: md ) {
            font-size: r(10);
            height: 8em;
        }
    }

    &__logo {
        @include mq( $until: md ) {
            width: 15em;
            margin-bottom: 1.5em;
        }
            
        @include mq( $from: md, $until: lg ) {
            
        }
            
        @include mq( $from: md ) {
            width: 20em;
        }

        svg {
            @extend %block;
        }
    }

    // ================================================================
    // NAV
    // ================================================================

    &__nav {
        @extend %flex;
        @extend %relative;

        @include mq( $until: md ) {
            align-items: center;
            background-color: currentColor;
            height: r(map-get($HEADER_HEIGHT, SM));
            width: 100%;
            justify-content: center;
        }
    }

    &__link {
        @extend %relative;
        @extend %transition;
        
        @include mq( $until: md ) {
            margin: 0 0.5em;
            color: c(white);
            height: 100%;
            display: flex;
            align-items: center;

            &[href="#top"] {
                display: none;
            }
        }
            
        @include mq( $from: md ) {
            margin: 0 1em;
            padding-bottom: 0.5em;
            border-bottom: r(4) solid transparent;
        }

        &.Current,
        &:hover {
            
            @include mq( $until: md ) {

                @include after {
                    position: absolute;
                    display: block;
                    background-color: white;
                    height: r(2);
                    width: 100%;
                    bottom: 0;
                }

            }
                
            @include mq( $from: md, $until: lg ) {
                
            }
                
            @include mq( $from: md) {
                border-color: currentColor;
            }
        }
    }


    
}



